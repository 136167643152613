import i18n from '@/i18n/index'
function getDomainConfigure() {
  // console.log(document.domain)
  let host = document.domain
  switch (host) {
    case '373hs.com':
    case 'www.373hs.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs',
        domain: '373hs.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TBVK3TprwJFphzzeWc4KHc6PeULe5i6666',
          big: 'TPxS27r3HhiPQKmhwkbWE7fJzDDfAy8888',
          double: 'TXPZkXrvAUR9REgqSNatsrMa6ZfW9P7777',
          bull: 'TRKXVe8Eb1gFGfF5TUKr8K7Kq834md5555',

          // single2: 'TBVK3TprwJFphzzeWc4KHc6PeULe5i6666',
          // big2: 'TPxS27r3HhiPQKmhwkbWE7fJzDDfAy8888',
          // double2: 'TXPZkXrvAUR9REgqSNatsrMa6ZfW9P7777',
          // bull2: 'TRKXVe8Eb1gFGfF5TUKr8K7Kq834md5555',
          VIP_single: 'TQzTMAxUNfnFs6CsYPr6ik9Xt9DRts7777',
          VIP_big: 'TA3vu6UbipiB2hFuxtpbi3UbSBseAN8888',
          VIP_double: 'TAurnopxigvKJ6wcH7zChuNEFANSJz8888',
          VIP_bull: 'TFmnQ5jZBFsZ5K6PdtvF9pYWVC5G9J6666',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          normalPool2: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/KF_373',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgpd',
            link: 'https://t.me/Hash_373_Group',
            txt: i18n.t('tgpd'),
          },
          {
            type: 'service_wsap',
            link: 'https://api.whatsapp.com/send/?phone=995555722437&text&type=phone_number&app_absent=0',
            txt: i18n.t('wsap'),
          },
          {
            type: 'service_ml',
            link: 'https://chatapi02.dniiag.com/chat/pc/chatMerchant?id=1134&type=server&orderNumber=220711220125HTMTK1&name=%E6%9B%BC%E8%94%93',
            txt: i18n.t('ml'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs3.com':
    case 'www.373hs3.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs3',
        domain: '373hs3.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TUE5RbruaPGw22r7hkp9iqaddf1y9f2222',
          big: 'TGvRBvwS9ahh4ksoUWGRvivFWEDBCB5555',
          double: 'TH4c5Nrys3HMgaEPQeiSLXjHfgCcCh7777',
          bull: 'TLiBrJNUqdAyiMV4RiazEKZkA3BWRD9999',
          VIP_single: 'TYfb65D5khE9pmBuFAHmxAGpe7baCc2222',
          VIP_big: 'TMsAYSsMSjtLMwqQFxY3M4TfuTVR577777',
          VIP_double: 'TXfcpCd1yzppQ95njwXVVcG3t1w8ny1111',
          VIP_bull: 'TUbVdYRhG8eyLFCpVcZF4B657Xo8dV5555',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/KF_373',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs1.com':
    case 'www.373hs1.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs1',
        domain: '373hs1.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'THot8jLzYZx2XWWpV8byxSyeSxZx4E4444',
          big: 'TB1Cvb8XYHFY4ZxT2ezpVyRS9RcFUC3333',
          double: 'TLyH5bd4f6qcmwzTdKaKVij1wPtzCy8888',
          bull: 'TSTbgqhDL9zvtLgwUSMJQuFVX97YFx5555',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/sakipaul',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100_5_10_1'),
          big: i18n.t('Limit100_5_10_1'),
          double: i18n.t('Limit100_5_10_1'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs2.com':
    case 'www.373hs2.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs2',
        domain: '373hs2.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'TLY5CPQVLpXfi93UPD3FJntniyRFXM8888',
          big: 'TWN1E9DmGQdXDnxW1goVK6pxBuEhjd3333',
          double: 'TBnjeuCzwbvAJxjRAiWkR1WZddxAQm9999',
          bull: 'TNLM39r9KveT84VZPqAbeHjTmHAkBt3333',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/sandra3288',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100_5_10_1'),
          big: i18n.t('Limit100_5_10_1'),
          double: i18n.t('Limit100_5_10_1'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'l71t.com':
    case 'www.l71t.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: 'l71t',
        domain: 'l71t.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TBTi9X8nqiE1Vb1hk8bHNwZQFPPgdi2222',
          big: 'TCrmmaFz2xs22KH5zaLGZ5BJ7LFXVH3333',
          double: 'TNUmBQBPBzt1tqHxZBzQGewPYPWEKE2222',
          bull: 'TVJYBGD5aWaLDmCjSraMRkN2UxF9Z49999',
          VIP_single: 'TAexTwH6JcTVHckKCAC2ADhD6TbXpo5555',
          VIP_big: 'TLpPv1wwZqswUVpRs8gwzFonJw64Na8888',
          VIP_double: 'TLwD1zLyXRAv4ZtSNkbFUAKNmw4yKK5555',
          VIP_bull: 'TCKB8HvDh3Hd6mky6pqP3TQt8so8MQ5555',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/KF_373',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_195'),
          big: i18n.t('Odds_195'),
          double: i18n.t('Odds_195'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'l71o.com':
    case 'www.l71o.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: 'l71o',
        domain: 'l71o.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TSoHHN9MQj9HtYd1ixTDtjLaxsPome1111',
          big: 'THR7dw5hvwqvvgRaRpg4ct7BPfTaEd1111',
          double: 'TNdCAyFXrevE6pBhA6DT2z1yFogTgc8888',
          bull: 'TVEfvNP9CMpB7nxLb5KKuXGbxeVPBc4444',
          VIP_single: 'TGpEF6sFYLzY3wnyoKZsiAZrew7VcD2222',
          VIP_big: 'TGU5QDMr3WU7LutaCm2LDcbBZAzcWf1111',
          VIP_double: 'TJ5vqfhp1WrEiSEnEbpa459TQiSV2j2222',
          VIP_bull: 'TFD96fG81Kuu9NZYwZ2zXMQywPxsYu5555',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/KF_373',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }

    case 'l71q.com':
    case 'www.l71q.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: 'l71q',
        domain: 'l71q.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TGThT4MwGcfFbp2oRhS6LN6AcQWPg12222',
          big: 'TWbGfrazmM5JDo1MchhbQqJwHhfypY3333',
          double: 'TBY5qwaw9BLhJXUxEtmKbBexML2mp11111',
          bull: 'TEs9gCURv6ceVqV6qUPeUJQB26xE4k4444',
          VIP_single: 'TYRwYkSUcKdyDTzcTAunuXkWubd5mr2222',
          VIP_big: 'TSnX3ysvT6FpLxrUSjRJb6JocpufAj3333',
          VIP_double: 'TFLJoDsUBasxUxKdc9oYXwHcgf4oc31111',
          VIP_bull: 'TBsbYRi3tr4TriZiiUbFj6zHdp6VQg4444',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/kf_l71',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs15.com':
    case 'www.373hs15.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_tz'),
        title: '373hs15',
        domain: '373hs15.com',
        isVipOpen: false,
        isServerOpen: false,
        isAboutOpen: true,
        address: {
          single: '请联系代理获取',
          big: '请联系代理获取',
          double: '请联系代理获取',
          bull: '请联系代理获取',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [],
        limit: {
          single: i18n.t('Limit10_3'),
          big: i18n.t('Limit10_3'),
          double: i18n.t('Limit10_3'),
          bull: i18n.t('Limit100_30'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'tz88.vip':
    case 'www.tz88.vip':
      return {
        skin: 'skin1',
        name: i18n.t('name_tz'),
        title: 'tz88',
        domain: 'tz88.vip',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TGXHUmpkpRGaKHo9NiDx5NAC4GhhLi1111',
          big: 'TPoNgueTWZYu84djSR7b9MkGDiPcFF5555',
          double: 'TWTxmcNcuX1hH9PXuFTgfCpbCwogej3333',
          bull: 'TXngdJFzywi9XDZF44MyBqcJ7qJ3f76666',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/tianzunguojikefu',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/tianzunguojivipkefu',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_3'),
          big: i18n.t('Limit10_3'),
          double: i18n.t('Limit10_3'),
          bull: i18n.t('Limit100_30'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '909hs.com':
    case 'www.909hs.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_909'),
        title: '909hs',
        domain: '909hs.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TVY1e6v7Rz6aecQ97av38XRD61Qcjz2222',
          big: 'TLkXL5X5WfPytUkhCTSD5ppdLL6pxF7777',
          double: 'TL8RUEnpwL96jopaJ7ticBhAkfVJKE3333',
          bull: 'TZ5wguXuLRjVGKfVnbdWK2cYgV7EdY7777',

          // single2: 'TVY1e6v7Rz6aecQ97av38XRD61Qcjz2222',
          // big2: 'TLkXL5X5WfPytUkhCTSD5ppdLL6pxF7777',
          // double2: 'TL8RUEnpwL96jopaJ7ticBhAkfVJKE3333',
          // bull2: 'TZ5wguXuLRjVGKfVnbdWK2cYgV7EdY7777',
          VIP_single: 'TUsxjrBAYpJDYiibdjfjnPw1koif3p5555',
          VIP_big: 'TQ4BNUcSmcmo2HpR9n5fXiGqFPfB112222',
          VIP_double: 'TTnFqWM8q8uxjJ8KP6kcD51vQggR3q3333',
          VIP_bull: 'TQ2ZWCEdNUK4b2p7zEt4a9ukMjzhUb6666',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          normalPool2: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          // vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hs909hx',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/HS909KF',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgzs',
            link: 'https://t.me/HS909K',
            txt: i18n.t('tgzs'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs7.com':
    case 'www.373hs7.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs7',
        domain: '373hs7.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TMamptE8c16hjEVzoNrCXqP5FLvbxt8888',
          big: 'TAFQgfpTbCjBEuCnDscTNZYZGxJ1CG1111',
          double: '',
          bull: '',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hc74110',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs8.com':
    case 'www.373hs8.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs8',
        domain: '373hs8.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TW2LKSWsod43AjuLzbEaC6HWUAJ92F7777',
          big: 'TXueAfh8auiwK2nerRpQNuk9rUDx4h2222',
          double: '',
          bull: 'TDUX7LCU5udWCSYkxncQpq9ssYXnKx8888',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/klfc999',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs6.com':
    case 'www.373hs6.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs6',
        domain: '373hs6.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TMamptE8c16hjEVzoNrCXqP5FLvbxt8888',
          big: 'TAFQgfpTbCjBEuCnDscTNZYZGxJ1CG1111',
          double: '',
          bull: '',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hc74110',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs9.com':
    case 'www.373hs9.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs9',
        domain: '373hs9.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'TW62mTdZm1KMvMwuj77PqUWyQJQTfi7777',
          big: '',
          double: '',
          bull: 'TPPtNmFvWu9SVqvof8RauDjY3qHpuZ1111',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/kf373666',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_195'),
          big: i18n.t('Odds_195'),
          double: i18n.t('Odds_195'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs11.com':
    case 'www.373hs11.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs11',
        domain: '373hs11.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'TNToDbAGTphBPC29uY6dnGUNGawzRy3333',
          big: 'TB41XvGwKWencd3e4ZVkRTMvzE3Vjf1111',
          double: 'TCuEwZzjskQW6nejaPqEz1Yus9NPph6666',
          bull: 'TM7V9ogkdgpoxdnPVU716qiy1SThb62222',

          // single2: 'TNToDbAGTphBPC29uY6dnGUNGawzRy3333',
          // big2: 'TB41XvGwKWencd3e4ZVkRTMvzE3Vjf1111',
          // double2: 'TCuEwZzjskQW6nejaPqEz1Yus9NPph6666',
          // bull2: 'TM7V9ogkdgpoxdnPVU716qiy1SThb62222',

          VIP_single: 'TZF9Fn7Pw3bsmpk2jmzBgFZdTRDP774444',
          VIP_big: 'TAybtKFdEdayzakDQsciAFpRXTzu8d5555',
          VIP_double: 'TPmEBQdw8va8W54F7Kmhxt5byz8DJz4444',
          VIP_bull: 'TKkAyUbPSXegAP9YmCx4Axt8xUfLjs7777',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/vV_chacha',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '909hs1.com':
    case 'www.909hs1.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_909'),
        title: '909hs1',
        domain: '909hs1.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TMMzu2ZgMthnEXzbX4ksEVYLbd2T8g9999',
          big: 'TPHX7AJjB1whcSHeu3pHgXE6ybEhcu1111',
          double: 'TYUjFbapKH6dKWKEo1yQa5aqAvdthY4444',
          bull: 'TYx2BsqMpQwaK5YDRghdG1mkkYiBWy1111',

          // single2: 'TMMzu2ZgMthnEXzbX4ksEVYLbd2T8g9999',
          // big2: 'TPHX7AJjB1whcSHeu3pHgXE6ybEhcu1111',
          // double2: 'TYUjFbapKH6dKWKEo1yQa5aqAvdthY4444',
          // bull2: 'TYx2BsqMpQwaK5YDRghdG1mkkYiBWy1111',

          VIP_single: 'TLCcZaBKjq26YfQdQbK99rawc7BXgH5555',
          VIP_big: 'TKGyc3rvGw4EPJKJY1PjUpVikE7Nc55555',
          VIP_double: 'TLiEaRv1FW4c2Fjt8XzoRzgSEJsBJu3333',
          VIP_bull: 'TRpEUsu8A1YTbp12AKgFLz5JtHQ3Cu4444',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hh905zy',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/hs909KF',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/hs909hx',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/hx909fq',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs12.com':
    case 'www.373hs12.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs12',
        domain: '373hs12.com',
        isVipOpen: true,
        isServerOpen: false,
        isAboutOpen: false,
        address: {
          single: 'TKwewrAsiDVfCTzUbxif7NFbrZaU9r7777',
          big: 'TX2SazcHLTrwkhhBzfhKBF9okLEyJs8888',
          double: 'TNRijkd9pCsat6F4FBE4xnf61uSrKb8888',
          bull: 'TP2WuYeUQSNM1TVewHrBPd5Zou8GAY8888',
          VIP_single: 'TR6yBk1iuXz7oQfofHMRqtqZC4Axqe2222',
          VIP_big: 'TAvq5wSdameofT9wKAugGRQFEo7FcU3333',
          VIP_double: 'TPotH5WCc52NmaRPDcakyDWC7xiRsm7777',
          VIP_bull: 'TXfMcqJEBUH9VfwvWbasAShwLc8PiF7777',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [],
        limit: {
          single: i18n.t('Limit10'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs13.com':
    case 'www.373hs13.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs13',
        domain: '373hs13.com',
        isVipOpen: false,
        isServerOpen: false,
        isAboutOpen: false,
        address: {
          single: 'TRB5t3HjwsbHZevaUBJvgqsze2AL262222',
          big: 'TBT62cokrQaCPf1S91pD6ddetRwxEN1111',
          double: 'TXFqpRyM6HUhQP1mMxSC9HuSXgUsHC8888',
          bull: 'TCPDesZrUfXs1dnoKB3MXRNAUjPsp11111',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TJorZQVVSvz72pRvp8nQW8kiP5vaAo6666',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [],
        limit: {
          single: i18n.t('Limit100_5_10_1'),
          big: i18n.t('Limit100_5_10_1'),
          double: i18n.t('Limit100_5_10_1'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs5.com':
    case 'www.373hs5.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hs5',
        domain: '373hs5.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TP9dTPBbDJdpm9NpWRS4zobCJwmrxJ5555',
          big: 'TPU5W7uTvivWy5yKFkJRiack6dFMrd2222',
          double: '',
          bull: 'TUgWsS6YgP2vfTUWHWUK6QTcFDTBfg8888',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          // vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/liu_99',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/lrj8384',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_wsap',
            link: 'https://api.whatsapp.com/send/?phone=639363932655&text&type=phone_number&app_absent=0',
            txt: i18n.t('wsap'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_196'),
          big: i18n.t('Odds_196'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hsa.com':
    case 'www.373hsa.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hsa',
        domain: '373hsa.com',
        isVipOpen: false,
        isServerOpen: false,
        isAboutOpen: false,
        address: {
          single: 'TXtYKkmm495Uwk245jEa1kFqjXwWoJ4444',
          big: '',
          double: '',
          bull: 'TRZtvSb4kiWprdWZnnjCenYSXwakPt9999',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          // vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: 'TXzZBfEpqMRP2qbRhmuG9EiyYEFRkBwYHe',
        },
        server: [],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_196'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hash.com':
    case 'www.373hash.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hash',
        domain: '373hash.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TNToDbAGTphBPC29uY6dnGUNGawzRy3333',
          big: 'TB41XvGwKWencd3e4ZVkRTMvzE3Vjf1111',
          double: 'TCuEwZzjskQW6nejaPqEz1Yus9NPph6666',
          bull: 'TM7V9ogkdgpoxdnPVU716qiy1SThb62222',
          VIP_single: 'TZF9Fn7Pw3bsmpk2jmzBgFZdTRDP774444',
          VIP_big: 'TAybtKFdEdayzakDQsciAFpRXTzu8d5555',
          VIP_double: 'TPmEBQdw8va8W54F7Kmhxt5byz8DJz4444',
          VIP_bull: 'TKkAyUbPSXegAP9YmCx4Axt8xUfLjs7777',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/vV_chacha',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hs88.com':
    case 'www.373hs88.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_dfh'),
        title: '373hs88',
        domain: '373hs88.com',
        isVipOpen: false,
        isServerOpen: false,
        isAboutOpen: false,
        address: {
          single: 'TEMMdZwhUnz8AijYZXFqUeJ2FA6Goh2222',
          big: 'TTpRu626TFH9zASAzte8exw6dkpSYC9999',
          double: 'TBp4fhyTDZFvV2bfaEDP98Md4zqrcJ9999',
          bull: 'TTmGeoJnSfvxqGuFEbDyC379yvRJCY4444',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [{}],
        limit: {
          single: i18n.t('Limit10_10_10_1'),
          big: i18n.t('Limit100_10_10_1'),
          double: i18n.t('Limit100_10_10_1'),
          bull: i18n.t('Limit1000_10_100_1'),
          vip: i18n.t('Limit20'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '8043.com':
    case 'www.8043.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_8043'),
        title: '8043',
        domain: '8043.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TVrjn2UW1tVcTPogCZfKj74UEvbWSc3333',
          big: 'TVScQXyuS7MtxKK2xn8yyRfqZj1D3t4444',
          double: 'THpTQmDCTfnWC9RHtqjQG4XZa4yD2g4444',
          bull: 'TYqPuWC5G6BxN4HYbSLFTum1ManpMY8888',
          VIP_single: 'TYBHC69HUy4gvmBHb1iECWjqx4ecZL1111',
          VIP_big: 'TXy79HNsRVxin3XVnDxy3H6DBMQuDZ1111',
          VIP_double: 'TXWMYD8XqHuXoQa5wR5QXvmwhJ7dwE8888',
          VIP_bull: 'TXhZBgqmv6xqaNt2kSmcY5DizmFC6R2222',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/kefu_vixus',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'l71.com':
    case 'www.l71.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_l71'),
        title: 'l71',
        domain: 'l71.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TNToDbAGTphBPC29uY6dnGUNGawzRy3333',
          big: 'TB41XvGwKWencd3e4ZVkRTMvzE3Vjf1111',
          double: 'TCuEwZzjskQW6nejaPqEz1Yus9NPph6666',
          bull: 'TM7V9ogkdgpoxdnPVU716qiy1SThb62222',
          VIP_single: 'TZF9Fn7Pw3bsmpk2jmzBgFZdTRDP774444',
          VIP_big: 'TAybtKFdEdayzakDQsciAFpRXTzu8d5555',
          VIP_double: 'TPmEBQdw8va8W54F7Kmhxt5byz8DJz4444',
          VIP_bull: 'TKkAyUbPSXegAP9YmCx4Axt8xUfLjs7777',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/vV_chacha',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '8043z.com':
    case 'www.8043z.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_8043'),
        title: '8043z',
        domain: '8043z.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TUvhfgqHiRgEbutBqwEiJN2xEw5rmA4444',
          big: 'TVGfKX4Q5KKcpRfCy2ZhUjr2xFq9xp2222',
          double: 'TUo5qfxY8cSJqtAEsHMbRPSRwjZWnk5555',
          bull: 'TUnjcQcgkjFGgMGVtsKdwgF5BKpZ531111',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_ml',
            link: 'https://chatapi02.dniiag.com/chat/serverLogin?initializeType=server&serverNo=sc974410273180418908939547531973&companyId=1849026_kf8043&memberId=&memberName=&language=zh',
            txt: i18n.t('ml'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_196'),
          big: i18n.t('Odds_196'),
          double: i18n.t('Odds_196'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hsc.com':
    case 'www.373hsc.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hsc',
        domain: '373hsc.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TU69SbUJSynz7KhPvVMupDG8ZiYm483333',
          big: 'TUGuteR1LttHcf9SXzXHeQwj1NGCg14444',
          double: 'TTWKWAJwzZqtZkNc4SfrXJBjGYYPAy1111',
          bull: 'TTkxWkjjzpsjYzuzcLKZWgsQKPyZgF6666',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_ml',
            link: 'https://chatapi02.dniiag.com/chat/serverLogin?initializeType=server&serverNo=sc974410273180418908939547531973&companyId=1849026_kfl71&memberId=&memberName=&language=zh',
            txt: i18n.t('ml'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_196'),
          big: i18n.t('Odds_196'),
          double: i18n.t('Odds_196'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '909hs3.com':
    case 'www.909hs3.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_909'),
        title: '909hs3',
        domain: '909hs3.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TShcJ8DKyyk8eWzLNyRccSPsizxemC7777',
          big: 'TTFJxowiyKNf3e4bEQw7hPzqdmupvB7777',
          double: 'TS2T9X7d1qjiW16sK6CEJLd3cMY5id3333',
          bull: 'TS2jn9AtD4KxpJfj1kHqEtYxcKFLWk4444',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_ml',
            link: 'https://chatapi02.dniiag.com/chat/serverLogin?initializeType=server&serverNo=sc974410273180418908939547531973&companyId=waifang01&memberId=&memberName=&language=zh',
            txt: i18n.t('ml'),
          },
        ],
        limit: {
          single: i18n.t('Limit100'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_196'),
          big: i18n.t('Odds_196'),
          double: i18n.t('Odds_196'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '909hs5.com':
    case 'www.909hs5.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_909'),
        title: '909hs5',
        domain: '909hs5.com',
        isVipOpen: true,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TRvLpro7m4U8W1cSNXCu6S73MpfA8d8888',
          big: 'TRyzgLCF16pEzb2zped7Vgc17FY6KN1111',
          double: 'TRVb2tgYH4wwdVix1vX5oHaSXWQ5eF1111',
          bull: 'TRsVH2xCNgETWCo8fJ7BmgeRRWB6hf6666',
          VIP_single: 'TQSs3uiTGq57Gd8VWga5rratQFdPNE3333',
          VIP_big: 'TRHzvzLRubrrEfyq7pbvpgHvJWmxhg7777',
          VIP_double: 'TQG9hT5X4kkkFGhdVidjZ1Wop79CnV1111',
          VIP_bull: 'TQdhZjbPfYrezPbRDonE8a5nV5w9uW9999',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hue4g',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'mp4hash.com':
    case 'www.mp4hash.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_mp4'),
        title: 'mp4hash',
        domain: 'mp4hash.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'TNZw3DmvzrdUoqSf2sUgQfVeHbVkrw5555',
          big: 'TNR4HxrYxiguDzDtY1cbAMrPhvm3DZ1111',
          double: '',
          bull: 'TNqHPXr7PWtvWNxik6s2r5jBhNeW9E2222',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/MP4_dd',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgzs',
            link: 'https://t.me/MP4_king',
            txt: i18n.t('tgzs'),
          },
        ],
        limit: {
          single: i18n.t('Limit100_30'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_195'),
          big: i18n.t('Odds_195'),
          double: i18n.t('Odds_195'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case '373hse.com':
    case 'www.373hse.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_373'),
        title: '373hse',
        domain: '373hse.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TJd78Kpft1ta8n2AQwReVecMKDjd586666',
          big: 'THwtCQfwryWPQzXbT59cK2bPoymtzD8888',
          double: 'THUshS7XzCXenpRiEd51QXcbJpLrut2222',
          bull: 'THkTY1MgWgNL8zKxF963e7vmW6xB8d5555',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/kf_bodu',
            txt: i18n.t('tgkf'),
          },
        ],
        limit: {
          single: i18n.t('Limit10_30_10_3'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
          vip: i18n.t('vipLimit10_100_1_10'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
          VIP_single: i18n.t('Odds_195'),
          VIP_big: i18n.t('Odds_195'),
          VIP_double: i18n.t('Odds_195'),
          VIP_bull: i18n.t('Odds_195'),
        },
      }
    case 'bolehash.com':
    case 'www.bolehash.com':
      return {
        skin: 'skin1',
        name: i18n.t('name_bole'),
        title: 'bolehash',
        domain: 'bolehash.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: false,
        address: {
          single: 'TCvWB1WHk3LYDmrntzUwFZr6ZAYW8h9999',
          big: 'TCSjuxTZW4vZSLK36Bzqe2hC1gPm4R8888',
          double: 'TCroeDha3QnbVEAaUvkWuiC9GaMsu32222',
          bull: 'TCQfTbUQJYL231eFKKuTmgzZ4GEntY5555',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hei8861',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/long99688',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_line',
            link: 'https://line.me/ti/p/wOjhetxjn0',
            txt: i18n.t('line'),
          },
        ],
        limit: {
          single: i18n.t('Limit10'),
          big: i18n.t('Limit100'),
          double: i18n.t('Limit100'),
          bull: i18n.t('Limit1000'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
        },
      }
    default:
      return {
        skin: 'skin1',
        name: i18n.t('name_bole'),
        title: 'bolehash',
        domain: 'bolehash.com',
        isVipOpen: false,
        isServerOpen: true,
        isAboutOpen: true,
        address: {
          single: 'TCvWB1WHk3LYDmrntzUwFZr6ZAYW8h9999',
          big: 'TCSjuxTZW4vZSLK36Bzqe2hC1gPm4R8888',
          double: 'TCroeDha3QnbVEAaUvkWuiC9GaMsu32222',
          bull: 'TCQfTbUQJYL231eFKKuTmgzZ4GEntY5555',
          VIP_single: '',
          VIP_big: '',
          VIP_double: '',
          VIP_bull: '',
          normalPool: 'TSgWsvYa33XSrdJmWn5LNvrq2V5dBBsd32',
          vipPool: '',
          otherPool: 'TJJtbPjSciZaaW2ck58zfcr2KL2tWq1T8b',
          otherPool2: 'TCj3epGtiPmnQQNF9ZwRWyEXqNEbEMVhNN',
          otherPool3: '',
        },
        server: [
          {
            type: 'service_tgkf',
            link: 'https://t.me/hei8861',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/diao7787',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_tgkf',
            link: 'https://t.me/long99688',
            txt: i18n.t('tgkf'),
          },
          {
            type: 'service_line',
            link: 'https://line.me/ti/p/wOjhetxjn0',
            txt: i18n.t('line'),
          },
        ],
        limit: {
          single: i18n.t('Limit100_30'),
          big: i18n.t('Limit100_30'),
          double: i18n.t('Limit100_30'),
          bull: i18n.t('Limit1000_30_100_3'),
        },
        rate: {
          single: i18n.t('Odds_198'),
          big: i18n.t('Odds_198'),
          double: i18n.t('Odds_198'),
          bull: i18n.t('Odds_195'),
        },
      }
  }
}

export default {
  getDomainConfigure,
}
