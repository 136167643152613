export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: '최신 게임',
  games_BoxLast: '로마 ',
  newGames: '인기있는 게임',
  games: '게임 디스플레이',
  games_more: '더 배우기',
  games_newdes:
    '다양한 게임, 다양한 테마 및 다양한 게임 플레이. 우리는 고객들에게 최고의 게임 경험을 제공하기 위해 노력합니다.',
  games_des:
    '다양한 플레이 방식, 화려한 그래픽, 독특하게 제작된 스토리라인;매 순간마다 새로운 탐험과 놀라움이 기다리며, 전례 없는 게임 경험을 느껴보세요! ',
  games_newspan: '모든 게임',
  aboutUstext1: '우리에 대해',
  aboutUstext2:
    'GFG는 세계 최고의 게임 공급 업체입니다. 열정은 전 세계의 전문가들을 하나로 모아 협력적인 팀을 형성하는 데 기여합니다. 책임감은 우리가 게임 개발에서 공정한 플레이를 최우선으로 여기는 이유입니다. 무한한 가능성은 GFG 게임 월드 - 순수한 상상력의 세계를 창조한 것입니다.',
  aboutUstext3:
    'GFG는 세계 최고의 게임 공급 업체입니다. 열정은 전 세계의 전문가들을 하나로 모아 협력적인 팀을 형성하는 데 기여합니다. 책임감은 우리가 게임 개발에서 공정한 플레이를 최우선으로 여기는 이유입니다. 무한한 가능성은 GFG 게임 월드 - 순수한 상상력의 세계를 창조한 것입니다.',
    mainData_h2: '1',
  mainData_p: '하나의 API',
  mainData_span:
    '게임을 완벽하게 연동하기 위해서는 하나의 API만으로 완벽합니다. 체스, 타이거 머신, 낚시 등의 멋진 게임들이 있습니다.',
  mainCenter_h2: '5',
  mainCenter_p: '5개의 장치와 호환됩니다',
  mainCenter_span: '게임은 IOS/Android/Windows/MacOS/HTML5을 지원합니다.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10개 언어 지원됩니다',
  mainLanguage_span:
    '우리는 플레이어들이 자신의 모국어를 사용하여 콘텐츠를 더 잘 이해하고, 게임에 빠져들고, 놀라운 게임 플레이 경험을 즐길 수 있도록   하는 것에 약속합니다.',
  mainSafety_h2: '24',
  mainSafety_p: '24시간 온라인 서비스',
  mainSafety_span:
    '우리는 게임뿐만 아니라 24시간 운영되는 고객 서비스 팀도 제공하여 최고의 서비스를 제공합니다',
  about: '파트너',
  about_des:
    '동아시아, 동남아시아, 중국, 일본, 한국, 베트남, 태국, 인도네시아 및 말레이시아에 있는 사업의 최고의 파트너',
  vision: '연락하기',
  vision_des:
    '운영자, 에이전트 및 네트워크 공급자. 우리의 제품에 관심이 있으시면 언제든지 연락해 주십시오. 우리는 가능한 한 빨리 답변드리겠습니다.',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: '저작권 © GOOD FORTUNE GAMING 모든 권리 보유.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: '최신 뉴스',
  nav_All: '모두',
  nav_Latest: '최신 게임',
  nav_Preview: '이벤트',
  nav_Maintain: '유지 보수',
  hot_BoxFirst: '',
  hot_BoxLast: '로마',
  //游戏大全
  games_h1: '게임',
  navAll: '모두',
  navFish: '낚시',
  navChess: '보드 게임',
  navSlot: '슬롯',
  allgames_BoxFirst: '',
  allgames_BoxLast: '로마',
  // 游戏内页
  inner1: '홈',
  inner2: '게임 소개',
  inner3: '게임 사양',
  inner4: '게임 이름',
  inner5: '게임 유형',
  inner6: '게임 변동성',
  inner7: '중간',
  inner8: '실시간 전송 프로토콜',
  inner9: '최대 배수',
  inner10: '전체 크기',
  inner11: '초기 다운로드',
  inner12: '호환성',
  inner13: '지원 언어',
  //游戏名称
  gameName5: '홍흑대전',
  gameName9: '바카라',
  gameName10: '불불',
  gameName11: '용호상박',
  gameName12: '황금 꽃을 폭발하라',
  gameName14: '뱅커 디스 바',
  gameName18: '블랙잭',
  gameName22: '뱅커 빠이 까우',
  gameName25: '카지노 불불',
  gameName27: '와일드 불불',
  gameName29: '커미션 없는 바카라',
  gameName32: '슈퍼 불불',
  gameName113: '뱅커 불불',
  gameName115: '페이스 업 카드-셋',
  gameName117: '뱅커 페이스 카드-셋',
  gameName126: '페이스 업 카드-넷',
  gameName201: '황금 두꺼비 어업',
  gameName202: '재신 어업',
  gameName203: '용',
  gameName204: '인어 전설',
  gameName205: '월드컵 슈팅',
  gameName301: '행운의 비휴',
  gameName302: '행운 보물을 탈자',
  gameName303: '크리스마스 미션',
  gameName304: '왕래',
  gameName305: '마작으로 이겨라',
  gameName306: '보석의 별',
  gameName307: '777',
  gameName308: '조커 세배',
  gameName309: '버펄로',
  gameName310: '죽음의 책',
  gameName311: '콜로세움',
  gameName313: '용용용',
  gameName323: '선경탐보',
  gameName324: '행운의 황금돼지',
  gameDescribe5:
    '각 게임의 시작에서 각 쪽(빨간색 또는 검은색)의 세 장의 카드 중 두 장이 공개됩니다. 직관과 지혜를 따라 이길 쪽을 선택하세요!',
  gameDescribe9:
    '전 세계에서 가장 인기 있는 카지노 게임 중 하나입니다. 다양한 카드 조합이 가능하므로, 어떤 전략을 사용하실 건가요? 운을 시험해 보는 것일까요, 아니면 직관적인 선택일까요?',
  gameDescribe10:
    '은행가 불불을 플레이하는 것에 지치셨고 흥미를 더하고 싶으신가요? 플레이어들이 한 테이블에 모여서 배팅하는 이 게임을 시도해 보세요. 한 쪽 또는 여러 쪽에 배팅하여 승리 예측을 해보세요!',
  gameDescribe11:
    '수수료 없는 바카라와 비슷합니다. 용에 베팅하고, 호랑이에 베팅하세요. 승자가 모두 가져갑니다, 은행가는 벽에 기대어 지켜봅니다.',
  gameDescribe12:
    '상대방이 들키지 않은 손의 미스터리를 마주할 때, 당신은 그들의 베팅에 따라 결정을 내려야 합니다. 콜을 할지, 안 할지? 각 라운드는 당신의 인내력, 재치, 그리고 지혜를 최고의 시험에 놓습니다.',
  gameDescribe14:
    '중국의 양자강 델타 지역 해안성에서 기원한 일반인들 사이에서 인기있는 카드 게임. 카드를 뒤집어서 은행가를 결정한 후, 전략과 운의 전투가 시작됩니다.',
  gameDescribe18:
    '가능한 조합의 수가 제한된 카드 게임은 확률을 계산할 때 여러분의 기억력, 분석력, 계산 능력을 시험에 들게 합니다. 최선을 다하고 나머지는 운명에 맡기세요!',
  gameDescribe22:
    '위소보의 좋아하는 도미노 게임을 플레이하고 은행원이 될 기회를 잡아보세요. 추가 라운드와 더 높은 배수로 새로운 경험에 빠져보세요!',
  gameDescribe25:
    '각 게임 시작 시 단일 배수 금액을 선택하고 대결을 준비하세요. 승자는 모두 가져가며, 패자는 승자에게만 지불합니다. 이것이 작게 배팅하고 크게 이길 기회입니다.',
  gameDescribe27:
    '이 클래식한 은행원 카드 게임에는 와일드 카드 요소를 추가하기 위해 조커가 포함되어 있습니다. 그들은 그들의 소지자에 의해 그들이 선택한 어떤 카드로 지정될 수 있으므로, 가능한 카드 조합에 추가적인 놀라움 요소를 더합니다.',
  gameDescribe29:
    '수수료 없는 바카라는 은행원의 승리에 대한 수수료를 징수하지 않습니다. 수수료 없는 바카라로 동등한 지급을 받는 쾌감을 느껴보세요!',
  gameDescribe32:
    '은행원 불-불 게임에 지루함을 느끼셨나요? 베팅을 하러 테이블 주변에 모인 많은 플레이어들과 이 게임을 즐겨보세요. 한 쪽이나 여러 쪽에 베팅하여 승리 예측을 해보세요!',
  gameDescribe113:
    '은행원 불-불의 클래식 게임입니다. 은행원이 되는 기회를 잡고 모든 카드를 쓸어 담으실래요?',
  gameDescribe115:
    '잘 알려진 클래식인 Banker Bull-Bull의 인기 있는 새로운 변형입니다. 각 손의 시작에 다섯 장 중 세 장의 카드가 공개됩니다. 이 모든 정보를 손끝에 두고 어떻게 다음에 무엇을 할지 결정하실 겁니까?',
  gameDescribe117:
    '은행원 카드 게임의 더 간단한 변형으로 배우기 쉽습니다. 운명을 시험해 보세요, 이 행운의 게임에서!',
  gameDescribe126:
    '잘 알려진 클래식한 Banker Bull-Bull 의 인기 있는 새로운 변형. 각 손이 시작될 때 다섯 장의 카드 중 네 장이 공개됩니다. 이 모든 정보가 손끝에 있으면 다음에 무엇을 할지 어떻게 결정하겠습니까?',
  gameDescribe201:
    '한때 천 년 된 황금 두꺼비가 돈을 토해 내고, 그것이 생명의 땅으로 돌아올 것이라는 소문이 있었습니다. 바다의 모든 보물을 빼앗을 준비가 되셨습니까?',
  gameDescribe202:
    '재산의 신이 도착했습니다, 풍부함과 보물을 가져오고 있습니다. 멈출 수 없는 드릴 미사일이 발사되고 기관총 탄약이 빠르게 쌓여서 전체 탄약이 999개가 됩니다. 당신의 능력을 보여주세요!',
  gameDescribe203:
    '전설에 따르면 일곱 개의 드래곤 볼을 모두 모으면 드래곤 황제를 소환하고 소원을 빌 수 있다고 합니다. 드래곤 황제는 깊은 바다에서 발견되었고, 그것은 풍요롭고 행운을 약속합니다.',
  gameDescribe204:
    '자정에 머메이드의 노래의 천상의 곡조가 바다 위로 흘러나오고, 믿을 수 없는 보물이 바다 밑바닥에 있다는 약속... 용감한 모험가들아, 항해하라! 우리는 인어의 전설을 탐험합시다!',
  gameDescribe205:
    '전설들 사이의 전투. 최고의 슈터들이 적의 요새를 포위하고 점령합니다. 누가 돌파하여 월드컵을 지배할까요? GFG의 월드컵 슈팅으로 축구 열광을 경험해보세요!',
  gameDescribe301:
    '재산을 끌어당기는 것뿐만 아니라 행운도 주는 기적! 라인에 일치하는 두 개의 릴이 트리거되어 추가 스핀이 발생하며 승리 확률이 크게 향상됩니다! 행운의 릴을 돌려보고 최대 888배까지 승리금액을 배로 늘려보세요!',
  gameDescribe302:
    'GFG의 중국어 새해 슬롯! 선금의 바퀴가 돌아갑니다. 특별한 와일드로 승리를 최대 3000배까지 곱하세요! 행운의 고정이 울려 퍼집니다! 중국 새해를 맞이하여 모두에게 번영하고 성공하는 한 해를 기원합니다!',
  gameDescribe303:
    '동전이 비처럼 쏟아지는 딸랑딸랑 소리를 들어보세요! GFG는 크리스마스 미션으로 축제 분위기를 함께 즐기기를 제안합니다! 행운의 썰매를 3개 모아 기본급과 함께 즉시 무료 게임을 획득하세요! 최대 200회의 무료 스핀까지 획득하세요!',
  gameDescribe304:
    'GFG - 왕라이는 행운과 재산을 안겨줄 것입니다! 왕라이에서 와일드로 승리를 최대로 곱하세요! 행운의 등불을 수집하고 최소 8회의 무료 스핀을 즉시 획득하세요. 추가 연속 99회의 무료 게임까지 얻으세요!',
  gameDescribe305:
    '국민 문화의 정수인 마작 윈이 GFG에 등장합니다! 3개의 자진 승리 심볼이 12회의 무료 스핀을 발동시킵니다. 연속 제거로 최대 10배의 보너스를 획득하세요! 행운을 시험하고 다음 마작 킹이 되어보세요!',
  gameDescribe306:
    'GFG는 매일 밤 당신과 함께 합니다. 세 개의 유성을 모아 유성우를 일으킵니다. AKQJ의 소음에서 격리된 세계에서는 더 많은 보석만이 남아 있습니다. 당신의 성취를 즐기고 꿈 속에 몰두하세요.',
  gameDescribe307:
    '최고로 마법적인 숫자인 Triple 7. 클래식한 1x3 원버튼 슬롯 머신: 편안하게 베팅하고 방대한 재산을 얻으세요. 보상을 눈을 뜨고 100배로 증가시키세요. 운을 시험하고 Triple 7의 극도로 축복받은 상품을 가지고 돌아가세요.',
  gameDescribe308:
    '운이 좋으신가요? 좋아하는 숫자를 선택하고 시작 버튼을 누르세요. 승리를 위해 돌려봅시다. 간단하고 무작위로 조합된 숫자 조합으로 부를 얻으세요. 클래식한 원버튼 슬롯 머신으로 대박 상금을 획득할 수 없다는 누가 말하나요?',
  gameDescribe309:
    '모든 종류의 슬롯 머신 중에서 버팔로는 진정한 전설입니다. 그것은 라스베이거스에서 항상 가장 인기가 있었습니다. 이제 버팔로가 GFG를 지배하기 위해 나타났으며, 그 활기찬 모습과 함께 큰 부를 만들어갈 여행을 안내할 것입니다.',
  gameDescribe310:
    '죽음의 서 책에 감춰진 비밀을 밝힐 준비가 되셨나요? 이집트의 비밀 보물에 대한 문을 열어 보세요. 3권의 죽음의 서와 함께, 최대 200번의 무료 게임을 획득하세요. 영원한 재산을 소유하는 꿈이 이뤄질 수도 있습니다.',
  gameDescribe311:
    '풍요로움의 약속된 땅입니다. 모든 사람들은 가속력을 얻을 수 있는 기회를 갖습니다. 자신의 손으로 가난에서 부자로 가는 기회를 얻으세요. 싸워라! 피와 땀과 눈물로 절대적인 영광과 재산을 위해 싸워보세요.',
  gameDescribe313:
    '신성하고 귀한 운좋은 신수 - 롱롱롱. GFG에서 가장 클래식한 1X3 슬롯 머신이 다시 등장했습니다. 100배의 상금을 획득할 기회가 있습니다! 지금 용의 축복을 받으세요, 보는 사람 모두가 큰 부를 얻을 것입니다!',
  gameDescribe320:
    '스페이드, 하트, 다이아몬드, 클로버에 장식된 아름다운 풍경. 에이스, 킹, 퀸, 조커에 의해 엮여진 숨막히는 세계. 52장의 카드로 이루어진 판타지 세계에서, 궁극적인 광물을 소유한 사람이 풍부한 재산의 비밀을 간직하고 있습니다.',
  gameDescribe323:
    '이 신비로운 땅에서 불멸의 비밀과 무수한 재산이 당신을 기다리고 있습니다. GFG의 최신 론칭 Pokey를 플레이하여 천상의 땅으로의 초대를 확인하세요. 세 개의 황금 고급으로 끊임없는 부를 얻으세요.',
  gameDescribe324:
    '우리가 어렸을 때 돼지 저금통에 소중히 모은 동전들을 기억하시나요? 클래식한 GFG 1X3 슬롯은 당신의 어린 시절 꿈을 이뤄줄 것입니다. 최대 888배까지 배당금이 증가하는 것을 지켜보세요.',
  gameName320: '비장의 카드',
  qw: '공식 인증',
  qw_des:
    'GFG는 세계 최고의 게임 공급업체입니다. 우리의 열정과 혁신을 쏟아붓고, 다양한 스타일, 감동적인 사운드 효과, 매력적인 퍼포먼스, 공정한 게임 메커니즘을 완벽하게 게임에 통합합니다. 즐거움을 생활에 통합하고 고객에게 최고의 엔터테인먼트 경험을 제공합니다.',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: '홍보 비디오',
  fishname: '트렌드 피싱 게임',
  fishname2: `트렌드 피싱 게임의 해저 세계에 오신 것을 환영합니다!<br/>
  여기에서는 재미와 도전이 어디에나 있습니다!<br/>
  우리와 함께 이 흥미로운 낚시 여행을 시작해 보세요!`,
  languageChange: '언어'
}
