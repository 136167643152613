export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'Trò chơi mới nhất',
  games_BoxLast: 'Roma ',
  newGames: 'Trò chơi phổ biến',
  games: 'Hiển thị trò chơi',
  games_more: 'Học thêm',
  games_newdes:
    'Chúng tôi nỗ lực cung cấp cho khách hàng trải nghiệm chơi game tuyệt vời nhất với nhiều trò chơi, nhiều chủ đề và nhiều cách chơi.',
  games_des:
    'Nhiều cách chơi đa dạng, hình ảnh lộng lẫy, cốt truyện độc đáo và sáng tạo;Mỗi khoảnh khắc đều là một trải nghiệm khám phá mới và bất ngờ, cảm nhận một trải nghiệm chơi game chưa từng có!',
  games_newspan: 'Tất cả các trò chơi',
  aboutUstext1: 'Về chúng tôi',
  aboutUstext2:
    'GFG là nhà cung cấp game hàng đầu trên thế giới. Đam mê là điều gắn kết các chuyên gia từ khắp nơi trên thế giới thành một đội làm việc cùng nhau. Trách nhiệm là lý do tại sao chúng tôi đặt sự công bằng cao nhất trong quá trình phát triển game của chúng tôi. Vô hạn khả năng là điều đã tạo ra GFG Game World - một thế giới của trí tưởng tượng thuần khiết.',
  aboutUstext3:
    'GFG là nhà cung cấp game hàng đầu trên thế giới. Đam mê là điều gắn kết các chuyên gia từ khắp nơi trên thế giới thành một đội làm việc cùng nhau. Trách nhiệm là lý do tại sao chúng tôi đặt sự công bằng cao nhất trong quá trình phát triển game của chúng tôi. Vô hạn khả năng là điều đã tạo ra GFG Game World - một thế giới của trí tưởng tượng thuần khiết.',
    mainData_h2: '1',
  mainData_p: 'API đơn',
  mainData_span:
    'Chỉ cần một API là bạn có thể hoàn hảo kết nối các trò chơi hấp dẫn như cờ bạc, máy xèng, bắn cá và nhiều thứ khác.',
  mainCenter_h2: '5',
  mainCenter_p: 'Tương thích với năm thiết bị.',
  mainCenter_span: 'Trò chơi hỗ trợ IOS / Android / Windows / MacOS / HTML5.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 ngôn ngữ được hỗ trợ:',
  mainLanguage_span:
    'Chúng tôi cam kết cho phép người chơi sử dụng ngôn ngữ gốc của họ để hiểu rõ hơn nội dung, sâu vào trò chơi và tận hưởng trải nghiệm chơi game hấp dẫn.',
  mainSafety_h2: '24',
  mainSafety_p: 'Dịch vụ trực tuyến 24 giờ',
  mainSafety_span:
    'Chúng tôi không chỉ cung cấp các trò chơi, mà còn có đội ngũ Dịch vụ Khách hàng luôn sẵn sàng, cung cấp cho bạn dịch vụ tối đa.',
  about: 'Đối tác',
  about_des:
    'Đối tác tốt nhất của châu Á, có mặt tại Đông Á, Nam Á, Trung Quốc, Nhật Bản, Hàn Quốc, Việt Nam, Thái Lan, Indonesia và Malaysia.',
  vision: 'Liên hệ chúng tôi',
  vision_des:
    'Nhà điều hành, đại lý và nhà cung cấp mạng. Nếu bạn quan tâm đến sản phẩm của chúng tôi, hãy liên hệ với chúng tôi. Chúng tôi sẽ trả lời bạn sớm nhất có thể.',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'BẢN QUYỀN © GOOD FORTUNE GAMING TẤT CẢ CÁC QUYỀN ĐƯỢC GIỮ LẠI.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'Tin tức mới nhất',
  nav_All: 'Tất cả',
  nav_Latest: 'Trò chơi mới nhất',
  nav_Preview: 'Sự kiện',
  nav_Maintain: 'Bảo trì',
  hot_BoxFirst: '',
  hot_BoxLast: 'Roma',
  //游戏大全
  games_h1: 'Trò chơi',
  navAll: 'Tất cả',
  navFish: 'Đánh cá',
  navChess: 'Trò chơi bàn',
  navSlot: 'Khe',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'Roma',
  // 游戏内页
  inner1: 'Trang chủ',
  inner2: 'Giới thiệu trò chơi',
  inner3: 'Thông số kỹ thuật trò chơi',
  inner4: 'Tên trò chơi',
  inner5: 'Loại trò chơi',
  inner6: 'Biến động trò chơi',
  inner7: 'Trung bình',
  inner8: 'Giao thức truyền tải thời gian thực',
  inner9: 'Bội số tối đa',
  inner10: 'Kích thước đầy đủ',
  inner11: 'Tải xuống ban đầu',
  inner12: 'Tương thích',
  inner13: 'Ngôn ngữ hỗ trợ',
  //游戏名称
  gameName5: 'Đại Chiến Đỏ Đen',
  gameName9: 'Baccarat',
  gameName10: 'Bull Bull',
  gameName11: 'Rồng Hổ',
  gameName12: 'Trác Kim Hoa',
  gameName14: 'Nhị Bát Cướp Cái',
  gameName18: 'Blackjack',
  gameName22: 'Bài Cửu Cướp Cái',
  gameName25: 'Bull Bull Casino',
  gameName27: 'Crazy Bull',
  gameName29: 'Baccarat Không Hoa Hồng',
  gameName32: 'Super Bull',
  gameName113: 'Bull Bull Cướp Cái',
  gameName115: 'Bull Bull Cướp Cái 3 Trang',
  gameName117: 'Bài Cào Cướp Cái',
  gameName126: 'Bull Bull Cướp Cái 4 Trang',
  gameName201: 'Bắn Cá Kim Thiềm',
  gameName202: 'Bắn Cá Thần Tài',
  gameName203: 'Rồng',
  gameName204: 'Truyền Thuyết Nàng Tiên Cá',
  gameName205: 'Bắn Súng World Cup',
  gameName301: 'Gấu trắng may mắn',
  gameName302: 'Đại phúc đại lợi',
  gameName303: 'Nhiệm vụ giáng sinh',
  gameName304: 'Vọng Lai',
  gameName305: 'Chiến binh mạt chược',
  gameName306: 'Ngôi sao châu báu',
  gameName307: '777',
  gameName308: 'Ba chú hề',
  gameName309: 'Trâu nước',
  gameName310: 'Cuốn sách tử thần',
  gameName311: 'Đấu Trường La Mã',
  gameName313: 'LongLongLong',
  gameName323: 'Săn Bảo Cõi Tiên',
  gameName324: 'Heo Vàng May Mắn',
  gameDescribe5:
    'Hai trong số ba lá từ mỗi bên (Đỏ hoặc Đen) sẽ được tiết lộ vào đầu mỗi trò chơi. Hãy theo dõi trực giác và trí tuệ của bạn để chọn một bên chiến thắng!',
  gameDescribe9:
    'Một trong những trò chơi casino phổ biến nhất trên thế giới. Với các tổ hợp thẻ khác nhau có thể, bạn sẽ sử dụng chiến lược nào? Sẽ là một cuộc cờ bạc vận may, hay lựa chọn theo trực giác?',
  gameDescribe10:
    'Bạn đã mệt mỏi với trò chơi Banker Bull-Bull và muốn thêm phần kích thích? Hãy thử trò chơi này nơi một đám đông người chơi tụ tập xung quanh một bàn để đặt cược. Đưa ra dự đoán chiến thắng của bạn bằng cách đặt cược vào một hoặc nhiều bên!',
  gameDescribe11:
    'Tương tự như Baccarat không hoa hồng. Đặt cược vào rồng, đặt cược vào hổ. Người chiến thắng lấy hết, ngân hàng đứng ngoài tường nhìn.',
  gameDescribe12:
    'Đối mặt với đối thủ với bí ẩn của những lá bài chưa lộ, bạn phải đưa ra quyết định theo cược của họ. Gọi hay không gọi? Mỗi vòng đều đặt sự kiên trì, sự nhạy bén, và trí tuệ của bạn lên bài kiểm tra cuối cùng.',
  gameDescribe14:
    'Một trò chơi bài phổ biến trong dân chúng, có nguồn gốc từ các tỉnh ven biển của khu vực đồng bằng sông Dương Tử ở Trung Quốc. Một khi người chủ ngân hàng đã được quyết định bằng cách lật một lá bài, cuộc chiến về chiến lược và may mắn bắt đầu.',
  gameDescribe18:
    'Một trò chơi bài với số lượng tổ hợp có thể có bị giới hạn sẽ thử thách kỹ năng ghi nhớ, phân tích và tính toán của bạn khi tính toán xác suất. Hãy cố gắng hết sức và để phần còn lại cho số phận!',
  gameDescribe22:
    'Chơi trò chơi domino yêu thích của Wei Xiaobao với sự thêm vào cơ hội trở thành người chủ bạc. Hòa mình vào trải nghiệm hoàn toàn mới với những vòng đấu thêm và các bội số cao hơn!',
  gameDescribe25:
    'Chọn một số lượng nhân vào đầu mỗi trận đấu và chuẩn bị cho một trận đấu quyết định. Người chiến thắng giành tất cả, và người thua chỉ trả cho người chiến thắng. Đây là cơ hội của bạn để đặt cược nhỏ và thắng lớn.',
  gameDescribe27:
    'Jokers được bao gồm trong trò chơi bài cổ điển này để thêm phần tử lá bài hoang dã. Chúng có thể được người giữ chỉ định là bất kỳ lá bài nào họ chọn, do đó thêm một yếu tố bất ngờ cho các tổ hợp bài có thể.',
  gameDescribe29:
    'Baccarat không thu phí không thu phí khi tay ngân hàng thắng. Hãy cảm nhận sự phấn khích khi nhận được các khoản thanh toán ngang bằng với Baccarat không thu phí!',
  gameDescribe32:
    'Bạn đã chán chơi Banker Bull-Bull và muốn tăng thêm phần hấp dẫn? Hãy thử trò chơi này nơi một đám đông người chơi tụ tập xung quanh bàn để đặt cược. Đưa ra dự đoán chiến thắng của bạn bằng cách đặt cược vào một hoặc nhiều bên!',
  gameDescribe113:
    'Một trò chơi cổ điển của Banker Bull-Bull. Nắm bắt cơ hội trở thành người chủ ngân hàng và quét sạch bộ bài?',
  gameDescribe115:
    'Một biến thể mới phổ biến của trò chơi cổ điển nổi tiếng là Banker Bull-Bull. Ba trên năm lá bài sẽ được tiết lộ ở đầu mỗi ván. Với tất cả thông tin này ngay trong tầm tay, bạn sẽ quyết định làm gì tiếp theo như thế nào?',
  gameDescribe117:
    'Một biến thể đơn giản hơn của các trò chơi bài ngân hàng, dễ học hỏi. Hãy đến và thử vận may của bạn trong trò chơi may mắn này!',
  gameDescribe126:
    'Một biến thể mới phổ biến của trò chơi cổ điển nổi tiếng "Banker Bull-Bull". Bốn trong số năm lá bài sẽ được tiết lộ vào đầu mỗi ván. Có tất cả thông tin này ngay trong tầm tay, bạn sẽ quyết định làm gì tiếp theo?',
  gameDescribe201:
    'Đã từng có một con cóc vàng ngàn năm tuổi thổi tiền, và người ta đồn đại rằng nó sẽ trở lại vùng đất của những người sống. Bạn đã sẵn sàng để cướp bóc tất cả các kho báu của biển chưa?',
  gameDescribe202:
    'Thần Tài đã đến, mang theo sự giàu có và kho báu. Các tên lửa khoan không thể ngăn cản được bắn ra, đạn máy kíp nạp nhanh cho đến khi đầy 999 viên. Hãy đến và thể hiện sức mạnh của bạn!',
  gameDescribe203:
    'Theo truyền thuyết, một khi bạn thu thập đủ bảy viên ngọc rồng, bạn có thể triệu hồi Long Đế và ước nguyện. Long Đế đã được nhìn thấy ở biển sâu, và với nó là lời hứa về sự phong phú và may mắn.',
  gameDescribe204:
    'Giai điệu thần tiên của bài hát tiên cá trôi lên trên biển vào nửa đêm, và lời hứa về một kho báu đáng kinh ngạc ở đáy biển...Hãy lên thuyền, những nhà phiêu lưu dũng cảm! Hãy khám phá Huyền thoại về nàng tiên cá!',
  gameDescribe205:
    'Cuộc chiến giữa các huyền thoại. Những cầu thủ xuất sắc tấn công và chiếm lấy pháo đài của đối thủ. Ai sẽ phá vỡ và thống trị World Cup? Trải nghiệm sự cuồng nhiệt bóng đá với World Cup Shooting của GFG!',
  gameDescribe301:
    'Không chỉ thu hút của cải, nó còn mang lại may mắn! Vòng quay thêm được kích hoạt bằng hai cuộn khớp hàng, làm tăng tỷ lệ chiến thắng của bạn một cách đáng kể! Quay cuộn may mắn và xem số tiền thắng của bạn nhân lên tối đa 888 lần!',
  gameDescribe302:
    'Khe cắm Tết Trung Quốc của GFG! Bánh xe may mắn quay. Tăng gấp đôi số tiền thắng với các biểu tượng hoang dã đặc biệt lên đến tối đa 3000X! Nghe tiếng gõ mừng thịnh vượng của quả chuông! Chào đón năm mới Trung Quốc, chúc mừng mọi người có một năm thành công và thịnh vượng!',
  gameDescribe303:
    'Tiếng kêu của tiền xu rơi như mưa! GFG mời bạn cùng chào đón mùa lễ hội với-Xmas Mission! Thu thập 3 chiếc xe tuần lộc và ngay lập tức giành được mức cơ bản cộng với trò chơi miễn phí! Giành chiến thắng lên đến 200 vòng quay miễn phí!',
  gameDescribe304:
    'GFG - Wang Lai sẽ mang đến may mắn và sự giàu có cho bạn! Tăng gấp đôi số tiền thắng với biểu tượng hoang dã trên Wang Lai! Thu thập lồng đèn tài lộc và ngay lập tức giành được tối thiểu 8 vòng quay miễn phí. Giành được lên đến 99 vòng chơi miễn phí liên tiếp thêm!',
  gameDescribe305:
    'Mahjong Win - một tinh hoa của văn hóa dân tộc - đang đến với GFG! 3 biểu tượng thắng tự rút kích hoạt 12 lượt quay miễn phí. Giành chiến thắng lên đến 10 lần thưởng với loạt loại bỏ liên tiếp! Hãy thử vận may của bạn và trở thành Nhà vô địch Mahjong tiếp theo!',
  gameDescribe306:
    'GFG dành cả đêm với bạn. Thu thập ba thiên thạch để mang lại một mưa sao băng. Trên thế giới được cô lập khỏi tiếng ồn AKQJ, chỉ còn lại nhiều hơn những viên ngọc. Ngập tràn trong thành tựu của bạn và mơ mộng tận hưởng.',
  gameDescribe307:
    'Triple 7, con số kỳ diệu nhất. Máy rút càng cổ điển 1x3: hãy cược một cách dễ dàng và giành được tài sản bao la. Nhân đôi phần thưởng của bạn lên tới 100 lần. Thử vận may và mang về sự phúc lộc vô cùng của con số ba 7.',
  gameDescribe308:
    'Bạn có cảm thấy may mắn không? Chọn số yêu thích của bạn và nhấn start. Hãy để nó quay vòng cho chiến thắng trong tầm tay. Giành được một gia tài với một combo số đơn giản và ngẫu nhiên. Ai nói rằng bạn không thể giành được giải thưởng lớn với một chiếc máy rút càng cổ điển?',
  gameDescribe309:
    'Trong số vô số máy đánh bạc, Buffalo là một huyền thoại thực sự. Nó luôn luôn được ưa chuộng nhất tại Las Vegas. Bây giờ Buffalo chuẩn bị thống trị GFG, đưa bạn vào một hành trình để tạo ra một tài sản lớn với sự sôi động của nó.',
  gameDescribe310:
    'Bạn đã sẵn sàng để tiết lộ bí mật trong Sách Của Người Chết chưa? Hãy mở cánh cửa đến kho báu bí mật ở Ai Cập với 3 Cuốn Sách Của Người Chết. Giành chiến thắng lên đến 200 vòng chơi miễn phí. Giấc mơ sở hữu cát-xê bất diệt có thể trở thành sự thật.',
  gameDescribe311:
    'Đây là đất hứa của sự phong phú. Mọi người đều có cơ hội để đi với tốc độ tối đa. Kiếm được cơ hội từ nghèo khó trở thành giàu có bằng chính đôi tay của mình. Chiến đấu! Chiến đấu cho vinh quang tuyệt đối và tài sản với máu, mồ hôi và nước mắt.',
  gameDescribe313:
    'Thần thú thiêng liêng và quý phái - Longlonglong. Máy chơi slot cổ điển 1X3 nhất định sẽ xuất hiện trở lại trong GFG, có cơ hội để giành giải thưởng gấp 100 lần! Hãy nhận được sự may mắn từ sự ban phước của con rồng ngay bây giờ, và tất cả mọi người mà bạn nhìn thấy sẽ có cơ hội nhận được sự giàu có vô tận!',
  gameDescribe320:
    'Được trang trí bởi các lá Spades, Hearts, Diamonds và Clubs. Một cảnh quan tuyệt đẹp được kết hợp bởi Ace, King, Queen và Joker. Trong thế giới tưởng tượng này với 52 lá bài, người nắm giữ quân bài trắng cuối cùng có bí mật về sự giàu có phong phú.',
  gameDescribe323:
    'Trong xứ sở bí ẩn này, bí quyết bất tử và vô số của cải đang chờ bạn. Hãy đến và chơi Pokey vừa được GFG ra mắt để xác nhận lời mời của bạn vào thiên đường. Kiếm được tài phú vô tận với ba cung vàng.',
  gameDescribe324:
    'Còn nhớ những đồng xu quý giá trong hòn hộp tiết kiệm lúc chúng ta còn nhỏ không? Máy chơi cổ điển GFG 1X3 sẽ biến giấc mơ thời thơ ấu của bạn thành hiện thực. Hãy đến và xem số tiền thắng của bạn nhân lên tới tối đa 888 lần.',
  gameName320: 'Siêu Át Chủ Bài',
  qw: 'Chứng nhận chính thức',
  qw_des:
    'GFG là nhà cung cấp trò chơi hàng đầu thế giới. Bằng cách đổ đầy sự nhiệt huyết và sáng tạo của chúng tôi, các phong cách đa dạng, hiệu ứng âm thanh động, màn trình diễn hấp dẫn và cơ chế game công bằng, hòa quyện hoàn hảo vào trò chơi của chúng tôi. Hòa quyện niềm vui vào cuộc sống và mang lại cho khách hàng trải nghiệm giải trí tốt nhất.',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'Game Promotional Video',
  fishname: 'Trò chơi câu cá đình đám',
  fishname2: `Chào mừng bạn đến với thế giới dưới đáy biển của trò chơi câu cá đình đám!<br/>
  Ở đây, niềm vui và thách thức đều ở mọi nơi!<br/>
  Hãy cùng chúng tôi bắt đầu cuộc phiêu lưu câu cá thú vị này nào!`,
  languageChange: 'Ngôn ngữ'
}
