export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'Latest games',
  games_BoxLast: 'Roma ',
  newGames: 'POPULAR GAMES',
  games: 'GAME DISPLAY',
  games_more: 'Learn More',
  games_newdes:
    'Various games, diverse themes, and multiple gameplays. We strive to provide our customers with the ultimate gaming experience.',
  games_des:
    'Varied gameplay, dazzling graphics, and uniquely crafted storylines; every moment is a fresh exploration and a surprise, experiencing an unprecedented gaming adventure!',
  games_newspan: 'All Games',
  aboutUstext1: 'ABOUT US',
  aboutUstext2:
    'Good Fortune Gaming(GFG) is the world’s top gaming provider. Passion is what brings together experts from all over the world to form a collaborative team. Responsibility is why we give fair play the highest priority in our game development. Infinite Possibilities is what created GFG Game World - a world of pure imagination.',
  aboutUstext3:
    'Good Fortune Gaming(GFG) is the world’s top gaming provider. Passion is what brings together experts from all over the world to form a collaborative team. Responsibility is why we give fair play the highest priority in our game development. Infinite Possibilities is what created GFG Game World - a world of pure imagination.',
    mainData_h2: '1',
  mainData_p: 'Single API',
  mainData_span:
    'With just one API, you can perfectly connect to exciting games such as board games, slot machines, and fishing.',
  mainCenter_h2: '5',
  mainCenter_p: 'Compatible with Five Devices',
  mainCenter_span: 'The game supports IOS/Android/Windows/MacOS/HTML5.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 Languages Supported',
  mainLanguage_span:
    'We are committed to allowing players to use their native languages to better understand the content, dive into games, and enjoy fascinating gameplay experience.',
  mainSafety_h2: '24',
  mainSafety_p: '24-Hour Online Service',
  mainSafety_span:
    ' We not only provide games, but also have Customer Service Team around the clock, providing you with the   most extreme service.',
  about: 'PARTNERS',
  about_des:
    'Asias best partner in business, with a presence in East Asia, Southeast Asia, China, Japan, South Korea, Vietnam, Thailand, Indonesia and Malaysia.',
  vision: 'CONTACT US',
  vision_des:
    'Operators, agents, and network providers. If you are interested in our products, feel free to contact us. We will get back to you as soon as possible.',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'COPYRIGHT © GOOD FORTUNE GAMING ALL RIGHTS RESERVED.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'Latest News',
  nav_All: '全部消息',
  nav_Latest: 'Latest games',
  nav_Preview: 'Events',
  nav_Maintain: 'Maintenance',
  hot_BoxFirst: '',
  hot_BoxLast: 'Roma',
  //游戏大全
  games_h1: 'Games',
  navAll: 'All',
  navFish: 'Fishing',
  navChess: 'Board Games',
  navSlot: 'Slots',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'Roma',
  // 游戏内页
  inner1: 'Home',
  inner2: 'Game Introduction',
  inner3: 'Game Specifications',
  inner4: 'Game Name',
  inner5: 'Game Type',
  inner6: 'Game Volatility',
  inner7: 'Moderate',
  inner8: 'RTP',
  inner9: 'Maximum Multiplier',
  inner10: 'Full Size',
  inner11: 'Initial Download',
  inner12: 'Compatibility',
  inner13: 'Supported Languages',
  //游戏名称
  gameName5: 'Red vs Black',
  gameName9: 'Baccarat',
  gameName10: 'Bull-Bull',
  gameName11: 'Dragon Tiger Game',
  gameName12: 'Golden Flower',
  gameName14: 'Banker This Bar',
  gameName18: 'Blackjack',
  gameName22: 'Banker Pai Gow',
  gameName25: 'Casino Bull-Bull',
  gameName27: 'Bull-Bull Wild',
  gameName29: 'No Commission Baccarat',
  gameName32: 'Super Bull-Bull',
  gameName113: 'Banker Bull-Bull',
  gameName115: 'Three Cards Up Banker Bull-Bull',
  gameName117: 'Banker Three-Facecard',
  gameName126: 'Four Cards Up Banker Bull-Bull',
  gameName201: 'Golden Toad Fish',
  gameName202: 'God of Wealth Fish',
  gameName203: 'Dragon',
  gameName204: 'Mermaid Story',
  gameName205: 'World Cup Shooting',
  gameName301: 'Lucky Pixiu',
  gameName302: 'Good Fortune',
  gameName303: 'Xmas Mission',
  gameName304: 'Wang Lai',
  gameName305: 'Mahjong Win',
  gameName306: 'Gemstone',
  gameName307: '777',
  gameName308: 'Joker Triple',
  gameName309: 'Buffalo',
  gameName310: 'Book of Death',
  gameName311: 'Roma',
  gameName313: 'LongLongLong',
  gameName323: 'Wonderland Treasure',
  gameName324: 'Piggy Fortune',
  gameDescribe5:
    'Two out of three cards from each side (Red or Black) will be revealed at the start of each game. Follow your intuition and wits to choose a winning side!',
  gameDescribe9:
    'One of the most popular casino games worldwide. With various possible card combinations, what strategy would you employ? Will it be a gamble of luck, or an intuitive choice?',
  gameDescribe10:
    'Tired of playing Banker Bull-Bull and want to spice things up? Try this game where a crowd of players gather around a table to place bets. Make your victory predictions by betting on one or multiple sides!',
  gameDescribe11:
    'Similar to No Commission Baccarat. Bet on the dragon, bet on the tiger. The winner takes all, with the banker watching on the wall.',
  gameDescribe12:
    'In the face of your opponents with the mystery of their unrevealed hands, you have a decision to make following their bet. To call or not to call? Each round puts your tenacity, wit, and wisdom to the ultimate test. ',
  gameDescribe14:
    'A popular card game among the commonfolk that originated from the coastal provinces of the Yangtze River Delta region in China. Once the banker has been decided with the flip of a card, a battle of strategy and luck ensues.',
  gameDescribe18:
    'A card game with a limited number of possible combinations puts your memory, analytical and computational skills to the test when calculating probabilities. Do your best and leave the rest to fate!',
  gameDescribe22:
    'Play Wei Xiaobaos favorite dominoes game with the added twist of grabbing the chance to be the banker. Immerse yourself in a brand new experience wih higher multipliers with extra rounds!',
  gameDescribe25:
    'Select a single multiplier amount at the start of each game and prepare for a showdown. The winner goes on to take all, and the loser only pays to the winner. This is your chance to bet small and win big.',
  gameDescribe27:
    'Jokers are included in this classic banker card game to add a wild card element. They can be designated by their holder as any card they choose, thus adding an additional element of surprise to possible card combinations.',
  gameDescribe29:
    'No Commission Baccarat doesns collect commission on banker hand wins. Feel the rush of receiving even payouts with No Commission Baccarat!',
  gameDescribe32:
    'Tired of playing Banker Bull-Bull and want to spice things up? Try this game where a crowd of players gather around a table to place bets. Make your victory predictions by betting on one or multiple sides!',
  gameDescribe113:
    'A classic game of Banker Bull-Bull. Grab the chance to be the banker and sweep the deck?',
  gameDescribe115:
    'A popular new variant of the well-know classic Banker Bull-Bull. Three out of five cards will be revealed at the beginning of each hand. Having all this information at your fingertips, how will you decide what to do next?',
  gameDescribe117:
    'A simpler variant of banker card games thats easy to learn. Come and test your fortune in this game of luck!',
  gameDescribe126:
    'A popular new variant of the well-know classic Banker Bull-Bull. Four out of five cards will be revealed at the beginning of each hand. Having all this information at your fingertips, how will you decide what to do next?',
  gameDescribe201:
    'There was once a thousand-year old Golden Toad that spews money, and it is rumoured that it is to return to the land of the living. Are you ready to loot all the treasures of the sea?',
  gameDescribe202:
    'The God of Wealth has arrived, bringing in richness and treasure. Unstoppable drill missles launched, machine gun ammo stockpiling fast till full ammo 999. Come and demonstrate your prowess!',
  gameDescribe203:
    'Legend holds that once you collect all seven dragon balls, you can summon the Dragon Emperor and make a wish. The Dragon Emperor has been sighted in the deep seas, and with it the promise of abundance and good luck.',
  gameDescribe204:
    'Ethereal tunes of mermaid songs drift over the sea at midnight, and the promise of an incredible treasure is at the bottom of the sea...Set sail, brave adventurers! Let us explore the Legend of the Mermaid!',
  gameDescribe205:
    'A battle among legends. Top shooters lay siege and seize the enemys fortress. Who will break through and dominate the World Cup? Experience the football frenzy with GFGs World Cup Shooting!',
  gameDescribe301:
    'Not only can it draw wealth, it can also bestow good luck! Extra spins triggered with two matched reels in line greatly enhances your win rate! Spin the fortune reels and watch your winnings multiply up to a maximum of 888 times!',
  gameDescribe302:
    'GFGs Chinese New Year slot!The wheel of good fortune turns. Multiply your winnings with special wilds up to a maximum 3000X! Hear the call of the fortune gong! Welcomes the Chinese New Year, wishing all a prosperous year of success!',
  gameDescribe303:
    'Clinkety-clink, hear the clink of coins raining down! GFG invites you to celebrate this festive season with-Xmas Mission! Collect 3 Fortune Sleighs and instantly win base pay plus free game! Win up to 200 free spins!',
  gameDescribe304:
    'GFG - Wang Lai will bring you luck and wealth! Multiply your winnings with wilds on Wang Lai! Collect fortune lanterns and instantly win a minimum 8 free spins.Win up to an additional consecutive 99 free games!',
  gameDescribe305:
    'Mahjong Win - a quintessence of national culture - is coming to GFG! 3 self-draw win symbols triggers 12 free spins. Win up to 10 times the bonus with continuous elimination! Come try your luck and become the next Mahjong King!',
  gameDescribe306:
    'GFG spends every single night with you. Collect three meteors to bring forth a meteor shower. With the world isolated from the noise of AKQJ, only more gems left. Bask in your achievements and immerse yourself in a dream.',
  gameDescribe307:
    'Triple 7, the most magical number. Classic 1x3 one-arm bandit: lets bet with ease and win vast wealth. Multiply your reward with an eye-watering 100x. Try you luck and bring home the ultra-blessings of triple 7.',
  gameDescribe308:
    'Are you feeling lucky? Pick your favorite number and press start. Let it spin for the victory at hand. Win a fortune with a simple and random combo of digits. Who says you cans win a grand prize with a classic one-arm bandit?',
  gameDescribe309:
    'Among all sorts of slot machines, Buffalo is a true legend.It has consistently been the most popular in Las Vegas.Now Buffalo is about to dominate GFG, taking you on a journey to make a grand fortune with its effervescence.',
  gameDescribe310:
    'Are you ready to unveil the secrets in the Book of the Dead? Open the gate to the secret treasure in Egypt with 3 Books of the Dead. Win up to 200 free games. The dream of owning eternal wealth may come true.',
  gameDescribe311:
    'This is the promised land of abundance. Everyone has the opportunity to go full throttle. Earn the chance to go from rags to riches with your own hands. Fight! Fight for the absolute glory and fortunes with blood, sweat, and tears.',
  gameDescribe313:
    'The sacred and noble auspicious beast-Longlonglong. The most classic 1X3 slot machine is reappear in GFG, there is a chance to win a 100 times prize! Get the luck of the dragon blessing now, and everyone you see will get huge wealth!',
  gameDescribe320:
    'Adorned by Spades, Hearts, Diamonds, and Clubs. A breathtaking landscape interwoven by Ace, King, Queen, and Joker. In this world of fantasy consisting of 52 cards, who has the ultimate trump card holds the secret to abundant riches.',
  gameDescribe323:
    'In this land of mystery, the secret to immortality and countless riches await you. Come and play GFGs newly-launched Pokey to validate your invitation into the heavenly land. Earn your endless fortunes with three golden gongs.',
  gameDescribe324:
    'Remember those cherished coins in our piggy banks back when we were young? The classic GFG 1X3 Slot will make your childhood dreams come true. Come and watch your winnings multiply up to a maximum of 888 times.',

  footText: 'COPYRIGHT © GOOD FORTUNE GAMING ALL RIGHTS RESERVED.',
  gameName320: 'Super Ace',
  qw: 'OFFICIAL CERTIFICATION',
  qw_des:
    'GFG is the top game supplier in the world. By pouring our enthusiasm and innovation, diversified styles, moving sound effects, attractive performances and fair game mechanism, perfect integration into our game. Integrate joy into life and provide customers with the best entertainment experience.',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'Game Promotional Video',
  fishname: 'Trending Fishing Game',
  fishname2: `Welcome to the underwater world!<br/>
  Here, fun and challenges are everywhere!<br/>
  Join us on this exciting fishing adventure!`,
  languageChange: 'Language'
}
