import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh_lang from './lang/zh'
import en_lang from './lang/en'
import th_lang from './lang/th'
// import id_lang from './lang/id'
import ko_lang from './lang/ko'
// import ru_lang from './lang/ru'
import ja_lang from './lang/ja'
import vi_lang from './lang/vi'
// import de_lang from './lang/de'
// import sv_lang from './lang/sv'
import es_lang from './lang/es'
import tw_lang from './lang/tw'
import pt_lang from './lang/pt'
import in_lang from './lang/in'
Vue.use(VueI18n)
let lang = localStorage.getItem("LANG_NAME")  // 获取缓存语言

if (!lang && window.navigator.language) {  // 判断默认语言
    // if (window.navigator.language == 'zh-CN') {
    //     lang = 'zh'
    // }else if (window.navigator.language == 'ko-KR') {
    //     lang = 'ko'
    // }else if (window.navigator.language == 'th-TH') {
    //     lang = 'th'
    // }else if (window.navigator.language == 'th-TW') {
    //     lang = 'tw'
    // }else {
    //     lang = 'en'
    // }
    
}


const i18n = new VueI18n({
    locale: lang, // 当前语言赋值
    // locale: 'en', // 当前语言赋值
    messages: {
        'zh': zh_lang,
        'en': en_lang,
        'th': th_lang,
        'ko': ko_lang,
        'ja': ja_lang,
        'vi': vi_lang,
        'es': es_lang,
        'tw': tw_lang,
        'pt': pt_lang,
        'in': in_lang,
    }
});
export default i18n