export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: '最新ゲーム',
  games_BoxLast: 'ローマ ',
  newGames: '人気のゲーム',
  games: 'ゲームディスプレイ',
  games_more: 'もっと学ぶ',
  games_newdes:
    'さまざまなゲーム、多様なテーマ、複数のゲームプレイ。私たちはお客様に最高のゲーム体験を提供することを目指しています。',
  games_des:
    '多様なプレイスタイル、華麗なグラフィック、独特のストーリーライン；毎瞬、新しい探求と驚きがあなたを待っており、前例のないゲーム体験を感じることができます！',
  games_newspan: 'すべてのゲーム',
  aboutUstext1: '私たちについて',
  aboutUstext2:
    'GFGは世界トップのゲームプロバイダーです。情熱こそが世界中の専門家を結集させ、共同のチームを形成する原動力です。責任感こそが、公正なプレイをゲーム開発の最優先事項とする理由です。無限の可能性こそが、GFGゲームワールドを創り出したものであり、それは純粋な想像力の世界です。',
  aboutUstext3:
    'GFGは世界トップのゲームプロバイダーです。情熱こそが世界中の専門家を結集させ、共同のチームを形成する原動力です。責任感こそが、公正なプレイをゲーム開発の最優先事項とする理由です。無限の可能性こそが、GFGゲームワールドを創り出したものであり、それは純粋な想像力の世界です。',
  mainData_h2: '1',
  mainData_p: '単一のAPI',
  mainData_span:
    '一つのAPIだけで、チェス、スロット、フィッシングなどの素晴らしいゲームを完璧に統合できます。',
  mainCenter_h2: '5',
  mainCenter_p: '5つのデバイスと互換性があります',
  mainCenter_span:
    'ゲームは、IOS/Android/Windows/MacOS/HTML5をサポートしています。',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 言語がサポートされています',
  mainLanguage_span:
    '私たちは、プレイヤーがネイティブ言語を使用してコンテンツをより理解し、ゲームに没頭し、魅力的なゲームプレイ体験を楽しむことを可能にすることにコミットしています。',
  mainSafety_h2: '24',
  mainSafety_p: '24時間オンラインサービス',
  mainSafety_span:
    '私たちはゲームを提供するだけでなく、24時間体制のカスタマーサービスチームを提供して、あなたに最も極端なサービスを提供しています。',
  about: 'パートナー',
  about_des:
    '東アジア、東南アジア、中国、日本、韓国、ベトナム、タイ、インドネシア、マレーシアなどで存在するアジアのビジネスで最高のパートナー。',
  vision: 'お問い合わせ',
  vision_des:
    'オペレーター、エージェント、およびネットワークプロバイダー。もし当社製品に関心を持っていただければ、お気軽にお問い合わせください。できるだけ早くご返信いたします。',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'コピーライト© GOOD FORTUNE GAMINGすべての権利は保留されます。',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: '最新ニュース',
  nav_All: 'すべて',
  nav_Latest: '最新のゲーム',
  nav_Preview: 'イベント',
  nav_Maintain: 'メンテナンスのお知らせ',
  hot_BoxFirst: '',
  hot_BoxLast: 'ローマ',
  //游戏大全
  games_h1: 'ゲーム',
  navAll: 'すべてのゲーム',
  navFish: 'フィッシングマシン',
  navChess: 'ボードゲーム',
  navSlot: 'スロット',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'ローマ',
  // 游戏内页
  inner1: 'ホーム',
  inner2: 'ゲーム紹介',
  inner3: 'ゲーム仕様',
  inner4: 'ゲーム名',
  inner5: 'ゲームの種類',
  inner6: 'ゲームの変動性',
  inner7: '中程度',
  inner8: 'リアルタイムトランスミッションプロトコル',
  inner9: '最大の乗数',
  inner10: 'フルサイズ',
  inner11: '初期ダウンロード',
  inner12: '互換性',
  inner13: 'サポートされる言語',
  //游戏名称
  gameName5: '赤黒大戦',
  gameName9: 'バカラ',
  gameName10: '牛牛',
  gameName11: 'ドラゴンタイガー',
  gameName12: 'ゴールデンフラワー',
  gameName14: 'バンカーディスバー',
  gameName18: 'ブラックジャック',
  gameName22: 'バンカー牌九',
  gameName25: 'カジノ牛牛',
  gameName27: 'ワイルド牛牛',
  gameName29: 'ノーコミッション・バカラ',
  gameName32: 'スーパー牛牛',
  gameName113: 'バンカー牛牛',
  gameName115: 'スリーカードバンカー牛牛',
  gameName117: 'バンカー三公',
  gameName126: 'フォーカードバンカー牛牛',
  gameName201: 'ゴールデントウドフィッシング',
  gameName202: '福の神フィッシング',
  gameName203: 'ドラゴンフィッシング',
  gameName204: 'マーメイド伝説',
  gameName205: 'ワールドカップ制覇',
  gameName301: 'ヒキュウ開運',
  gameName302: 'グッドフォーチュン',
  gameName303: 'クリスマスミッション',
  gameName304: 'ワンライ',
  gameName305: 'マージャン無双',
  gameName306: 'ジェムスター',
  gameName307: '777',
  gameName308: 'ジョーカートリプル',
  gameName309: 'バッファロー',
  gameName310: 'デスブック',
  gameName311: 'コロッセオ',
  gameName313: '龍龍龍',
  gameName323: 'ワンダーランド・トレジャー',
  gameName324: 'ピギーフォーチュン',
  gameDescribe5:
    '各ゲームの開始時に、各サイド（赤または黒）からカードのうち2枚が公開されます。直感と知恵に従って、勝つ方を選びましょう！',
  gameDescribe9:
    '世界中で最も人気のあるカジノゲームの一つです。さまざまなカードの組み合わせが可能なため、どのような戦略を採用しますか？運に任せるギャンブルになるのか、それとも直感的な選択になるのか？',
  gameDescribe10:
    'バンカーブルブルをプレイするのに疲れて、スパイスを加えたいと思いますか？多くのプレイヤーがテーブルの周りに集まってベットを置くこのゲームを試してみてください。一つまたは複数のサイドに賭けて、勝利の予測を立ててみましょう！',
  gameDescribe11:
    'ノーコミッションバカラと似ています。ドラゴンに賭け、タイガーに賭けます。勝者がすべてを持ち去り、銀行家は壁にもたれて見ています。',
  gameDescribe12:
    '彼らの手が明かされていないミステリーを持つ相手に立ち向かうとき、あなたは彼らのベットに続いて決定を下さなければなりません。コールするか、しないか？各ラウンドでは、あなたの粘り強さ、機知、そして知恵が最大の試練に置かれます。',
  gameDescribe14:
    '中国の揚子江デルタ地方の沿岸省から起源を持つ一般庶民の間で人気のあるカードゲーム。カードを裏返して銀行家が決まると、戦略と運の戦いが始まります。',
  gameDescribe18:
    '可能な組み合わせの数が限られたカードゲームは、確率を計算する際に、あなたの記憶力、分析力、計算力を試すものです。最善を尽くし、残りは運命に任せましょう！',
  gameDescribe22:
    '韋小宝のお気に入りのドミノゲームをプレイし、バンカーになるチャンスをつかむ新たなツイストが加わりました。追加ラウンドと高い乗数で全く新しい体験に没頭しましょう！',
  gameDescribe25:
    '各ゲームの開始時に一つの倍数額を選択し、対決に備えます。勝者は全てを手に入れ、敗者は勝者にのみ支払います。これは少なく賭けて大きく勝つチャンスです。',
  gameDescribe27:
    'このクラシックなバンカーのカードゲームには、ワイルドカード要素を加えるためのジョーカーが含まれています。それらは、持ち主によって任意のカードとして指定できるため、可能なカードの組み合わせにさらなる驚きの要素を加えます。',
  gameDescribe29:
    'ノーコミッションバカラは、バンカーの勝利に対する手数料を徴収しません。ノーコミッションバカラで、均等な支払いを受け取る興奮を感じてみてください！',
  gameDescribe32:
    'バンカーブルブルのゲームに飽きてしまった、そして物事を少しスパイシーにしたいですか？たくさんのプレイヤーがテーブルを囲んでベットを置くこのゲームを試してみてください。一つまたは複数の側面にベットをして勝利を予測しましょう！',
  gameDescribe113:
    'バンカーブルブルのクラシックなゲームです。バンカーになるチャンスをつかんで、デッキを一掃しましょうか？',
  gameDescribe115:
    'よく知られているクラシックなBanker Bull-Bullの人気の新しいバリエーションです。各ゲームの最初に5枚のカードのうち3枚が公開されます。この全ての情報をあなたの指先に持って、次に何をするかをどのように決定しますか？',
  gameDescribe117:
    '銀行員のカードゲームのより簡単なバリエーションで、学びやすいです。この運のゲームであなたの運を試してみませんか?',
  gameDescribe126:
    'よく知られたクラシックな「Banker Bull-Bull」の人気新バリエーション。各ハンドの始めに5枚のカードのうち4枚が公開されます。すべての情報を手元に持っているとき、次に何をするかどう決めますか？',
  gameDescribe201:
    '昔、千年もの間金の蟾蜍がお金を吐き出し、それが生者の土地に戻るという噂がありました。海のすべての宝を奪う準備はできていますか？',
  gameDescribe202:
    '財神が到着し、豊かさと宝を持ってきました。止められないドリルミサイルが発射され、機関銃の弾薬が早く溜まって999まで満タンになります。あなたの能力を見せてください！',
  gameDescribe203:
    '伝説によると、7つのドラゴンボールをすべて集めると、ドラゴン皇帝を呼び出し、願いを叶えることができると言われています。ドラゴン皇帝は深海で目撃され、それは豊かさと幸運の約束と共に現れます。',
  gameDescribe204:
    '真夜中に人魚の歌の幽玄なメロディが海上に漂い、信じられないほどの宝物が海の底にあるという約束...勇敢な冒険者たちよ、航海を始めよう！私たちは人魚の伝説を探検しましょう！',
  gameDescribe205:
    '伝説たちの戦い。トップシューターが敵の要塞を包囲し、奪い取ります。誰が突破し、ワールドカップを制するのでしょうか？GFGのワールドカップシューティングでサッカー熱を体験しましょう！',
  gameDescribe301:
    '富を引き寄せるだけでなく、幸運ももたらします！一致する2つのリールがラインにトリガーされ、追加のスピンが発生することで勝率が大幅に向上します！運命のリールを回転させて、最大で888倍まで勝利金を増やしましょう！',
  gameDescribe302:
    'GFGの中国の新年スロット！幸運の車輪が回転します。特別なワイルドで最大3000倍まで勝利を増やせます！幸運なゴングの呼び声を聞いてください！新年を迎え、皆様に繁栄と成功の年をお祈りします！',
  gameDescribe303:
    'コインの雨音を聞こえますか、カリンカリンと！GFGはあなたをXmasミッションでお祝いに招待します！幸運なそりを3つ集めてベースペイと一緒に即座にフリーゲームを獲得しましょう！最大200回のフリースピンまで勝ち取れます！',
  gameDescribe304:
    'GFG - 王ライはあなたに運と富をもたらします！王ライでワイルドを使ってあなたの勝利を倍増させましょう！幸運のランタンを集め、最低8回の無料スピンを瞬時に獲得しましょう。さらに連続して最大99回の追加の無料ゲームを獲得できます！',
  gameDescribe305:
    '国民文化の粋であるマージャンウィンがGFGにやってきます！自摸和了のシンボル3つで12回のフリースピンが発動します。連続で牌を消せば最大10倍のボーナスを獲得できます！運試しをして次なるマージャンキングになろう！',
  gameDescribe306:
    'GFGは毎晩あなたと一緒に過ごします。3つの流星を集めて流星群をもたらしましょう。AKQJの騒音から隔離された世界では、さらに多くの宝石が残っています。あなたの成果に浸り、夢に浸りましょう。',
  gameDescribe307:
    '最も魔法的な数字、トリプル7。クラシックな1x3のワンアームバンディット：簡単に賭けて莫大な富を手に入れましょう。目を見開いて報酬を100倍に増やしましょう。運試しをしてトリプル7の超祝福を持ち帰りましょう。',
  gameDescribe308:
    '運試しですか？お気に入りの数字を選んでスタートボタンを押してください。勝利を手にするために回しましょう。単純でランダムな数字の組み合わせで大金を獲得しましょう。クラシックなワンアームバンディットでも大当たりは狙えますよ。',
  gameDescribe309:
    'あらゆる種類のスロットマシンの中で、バッファローは真の伝説です。それはラスベガスで常に最も人気がありました。今、バッファローがGFGを席巻し、その躍動感と共に巨大な財産を築く旅にあなたを連れて行きます。',
  gameDescribe310:
    '死者の書に隠された秘密を明らかにする準備はできていますか？エジプトの秘宝の扉を開けてください。死者の書3冊で最大200回のフリーゲームを獲得しましょう。永遠の富を手に入れる夢が叶うかもしれません。',
  gameDescribe311:
    '豊かな地の約束された土地です。全員がフルスロットルに進むチャンスを持っています。自分の手で貧困から富裕へと昇り詰めるチャンスを手に入れましょう。戦いましょう！血、汗、涙を流しながら絶対的な栄光と財産を求めて闘いましょう。',
  gameDescribe313:
    '神聖で高貴な瑞獣ロンロンロン。最もクラシックな1X3スロットマシンがGFGで再登場し、100倍の賞金獲得のチャンスがあります！龍の祝福の運を手に入れ、あなたが見るすべての人に巨大な富をもたらしましょう！',
  gameDescribe320:
    'スペード、ハート、ダイヤモンド、クラブで飾られた息をのむような景色。エース、キング、クイーン、ジョーカーが織り交ぜる幻想の世界。52枚のカードで構成されるこの世界では、究極の切り札を持つ者が豊かな富の秘密を握っています。',
  gameDescribe323:
    'この神秘の地で不老不死の秘密と無数の富があなたを待っています。GFGの新しくローンチされたポーキーで天国の地への招待を確かめましょう。三つの黄金のゴングで終わりのない富を手に入れましょう。',
  gameDescribe324:
    '私たちが子供の頃、貯金箱に大切にしまっていたコインを覚えていますか？クラシックなGFG 1X3スロットは、あなたの子供時代の夢を叶えます。最大888倍まで勝利金が増えるのを見てください。',
  gameName320: 'スーパーエース',
  qw: '公式認証',
  qw_des:
    'GFGは世界最高のゲーム供給企業です。私たちの熱意と革新、多様なスタイル、感動的なサウンドエフェクト、魅力的なパフォーマンス、公正なゲームメカニズムをゲームに完全に統合します。喜びを人生に統合し、お客様に最高のエンターテイメント体験を提供します。',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: '宣伝ビデオ',
  fishname: 'ブーム釣りゲーム',
  fishname2: `ブーム釣りゲームの海底世界へようこそ！<br/>
  ここでは、楽しさと挑戦がいたるところにあります！<br/>
  私たちと一緒にこのエキサイティングな釣りの旅を始めましょう！`,
  languageChange: '言語'
}
