<template>
  <div id="app">
    <!-- <home/> -->
    <router-view/>
  </div>
</template>

<script>
// import home from '@/views/index.vue'
// import router from './router';
export default {
  name: 'app',
  components: {
    // home,
    // router
},
}
</script>

