export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'Últimos jogos',
  games_BoxLast: 'Roma ',
  newGames: 'Jogos Populares',
  games: 'Exibição de Jogo',
  games_more: 'Aprenda Mais',
  games_newdes:
    'Vários jogos, temas diversos e múltiplos jogos. Nós nos esforçamos para fornecer aos nossos clientes a melhor experiência de jogo.',
  games_des:
    'Modos de jogo variados, gráficos deslumbrantes, enredos com toques de mestria únicos;Cada momento é uma nova descoberta e uma surpresa, vivencie uma experiência de jogo nunca antes vista!',
  games_newspan: 'Todos os Jogos',
  aboutUstext1: 'Sobre Nós',
  aboutUstext2:
    'A GFG é a principal fornecedora de jogos do mundo. A paixão é o que reúne especialistas de todo o mundo para formar uma equipe colaborativa. A responsabilidade é o motivo pelo qual damos a maior prioridade ao jogo justo em nosso desenvolvimento de jogos. Possibilidades infinitas é o que criou o GFG Game World - um mundo de pura imaginação.',
  aboutUstext3:
    'A GFG é a principal fornecedora de jogos do mundo. A paixão é o que reúne especialistas de todo o mundo para formar uma equipe colaborativa. A responsabilidade é o motivo pelo qual damos a maior prioridade ao jogo justo em nosso desenvolvimento de jogos. Possibilidades infinitas é o que criou o GFG Game World - um mundo de pura imaginação.',
    mainData_h2: '1',
  mainData_p: 'API única',
  mainData_span:
    'Apenas uma API é necessária para integrar perfeitamente jogos emocionantes como xadrez, caça-níqueis e pesca.',
  mainCenter_h2: '5',
  mainCenter_p: 'Compatível com cinco dispositivos:',
  mainCenter_span: 'O jogo suporta IOS/Android/Windows/MacOS/HTML5.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 idiomas suportados',
  mainLanguage_span:
    'Estamos comprometidos em permitir que os jogadores usem suas línguas nativas para melhor entender o conteúdo, mergulhar nos jogos e desfrutar de uma experiência de jogo fascinante.',
  mainSafety_h2: '24',
  mainSafety_p: 'Serviço online 24 horas',
  mainSafety_span:
    'Não só fornecemos jogos, mas também temos uma equipe de atendimento ao cliente 24 horas por dia, oferecendo-lhe o serviço mais extremo.',
  about: 'Parceiros',
  about_des:
    'O melhor parceiro da Ásia nos negócios, com presença na Ásia Oriental, Sudeste Asiático, China, Japão, Coreia do Sul, Vietnã, Tailândia, Indonésia e Malásia.',
  vision: 'Contate-Nos',
  vision_des:
    'Operadores, agentes e provedores de rede. Se você estiver interessado em nossos produtos, não hesite em nos contatar. Nós retornaremos o mais rápido possível.',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright:
    'DIREITOS AUTORAIS © GOOD FORTUNE GAMING TODOS OS DIREITOS RESERVADOS.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'Tin tức mới nhất',
  nav_All: 'Tất cả',
  nav_Latest: 'Trò chơi mới nhất',
  nav_Preview: 'Sự kiện',
  nav_Maintain: 'Bảo trì',
  hot_BoxFirst: '',
  hot_BoxLast: 'Roma',
  //游戏大全
  games_h1: 'Trò chơi',
  navAll: 'Tất cả',
  navFish: 'Đánh cá',
  navChess: 'Trò chơi bàn',
  navSlot: 'Slot',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'Roma',
  // 游戏内页
  inner1: 'Página inicial',
  inner2: 'Introdução ao jogo',
  inner3: 'Especificações do jogo',
  inner4: 'Nome do jogo',
  inner5: 'Tipo de jogo',
  inner6: 'Volatilidade do jogo',
  inner7: 'Moderado',
  inner8: 'Protocolo de transmissão em tempo real',
  inner9: 'Multiplicador máximo',
  inner10: 'Tamanho completo',
  inner11: 'Download inicial',
  inner12: 'Compatibilidade',
  inner13: 'Idiomas suportados',
  //游戏名称
  gameName5: 'Vermelho x Preto',
  gameName9: 'Bacará',
  gameName10: 'Touro-Touro',
  gameName11: 'Jogo Dragão Tigre',
  gameName12: 'Flor Dourada',
  gameName14: 'Banqueiro deste Bar',
  gameName18: 'Vinte-e-Um',
  gameName22: 'Banqueiro Pai Gow',
  gameName25: 'Casino Touro-Touro',
  gameName27: 'Coringa de Touro-Touro',
  gameName29: 'Bacará Sem Comissão',
  gameName32: 'Super Touro-Touro',
  gameName113: 'Banqueiro Touro-Touro',
  gameName115: 'Banqueiro Touro-Touro com Três Cartões para Cima',
  gameName117: 'Banqueiro comTrês Cartões Facetados',
  gameName126: 'Banqueiro Touro-Touro com Quatro Cartões para Cima',
  gameName201: 'Peixe Sapo Dourado',
  gameName202: 'Deus do Peixe da Riqueza',
  gameName203: 'Dragão',
  gameName204: 'Estória da Sereia',
  gameName205: 'Tiro na Copa do Mundo',
  gameName301: 'Pixiu da Sorte',
  gameName302: 'Boa Fortuna',
  gameName303: 'Missão de Natal',
  gameName304: 'Wang Lai',
  gameName305: 'Vitória no Mahjong',
  gameName306: 'Pedra preciosa',
  gameName307: '777',
  gameName308: 'Coringa Triplo',
  gameName309: 'Búfalo',
  gameName310: 'Livro da Morte',
  gameName311: 'Roma',
  gameName313: 'LongLongLong',
  gameName323: 'Tesouro do País das Maravilhas',
  gameName324: 'Fortuna dos Porcos',
  gameDescribe5:
    'Duas de três cartas de cada lado (Vermelho ou Preto) serão reveladas no início de cada jogo. Siga sua intuição e inteligência para escolher um lado vencedor!',
  gameDescribe9:
    'Um dos jogos de cassino mais populares em todo o mundo. Com várias combinações possíveis de cartas, que estratégia você empregaria? Seria um jogo de sorte, ou uma escolha intuitiva?',
  gameDescribe10:
    'Cansado de jogar Banker Bull-Bull e quer apimentar as coisas? Experimente este jogo onde uma multidão de jogadores se reúne em volta de uma mesa para fazer apostas. Faça suas previsões de vitória apostando em um ou vários lados!',
  gameDescribe11:
    'Semelhante ao Baccarat sem comissão. Aposte no dragão, aposte no tigre. O vencedor leva tudo, com o banqueiro assistindo do muro.',
  gameDescribe12:
    'Diante de seus adversários com o mistério de suas mãos não reveladas, você tem uma decisão a tomar após a aposta deles. Chamar ou não chamar? Cada rodada coloca sua tenacidade, sagacidade e sabedoria no teste supremo.',
  gameDescribe14:
    'Um popular jogo de cartas entre o povo comum que se originou nas províncias costeiras da região do Delta do Rio Yangtzé na China. Uma vez que o banqueiro tenha sido decidido com o lançamento de um cartão, começa uma batalha de estratégia e sorte.',
  gameDescribe18:
    'Um jogo de cartas com um número limitado de combinações possíveis testa sua memória, habilidades analíticas e computacionais ao calcular probabilidades. Faça o seu melhor e deixe o resto para o destino!',
  gameDescribe22:
    'Jogue o jogo de dominó favorito de Wei Xiaobao com o acréscimo de ter a chance de ser o banqueiro. Mergulhe em uma experiência totalmente nova com multiplicadores mais altos e rodadas extras!',
  gameDescribe25:
    'Selecione um único valor multiplicador no início de cada jogo e prepare-se para um confronto. O vencedor leva tudo, e o perdedor só paga ao vencedor. Esta é a sua chance de apostar pequeno e ganhar grande.',
  gameDescribe27:
    'Os coringas estão incluídos neste clássico jogo de cartas do banqueiro para adicionar um elemento de carta curinga. Eles podem ser designados por seu detentor como qualquer carta que escolherem, adicionando assim um elemento adicional de surpresa às possíveis combinações de cartas.',
  gameDescribe29:
    'No Commission Baccarat não cobra comissão nas vitórias da mão do banqueiro. Sinta a emoção de receber pagamentos uniformes com No Commission Baccarat!',
  gameDescribe32:
    'Cansado de jogar Banker Bull-Bull e quer apimentar as coisas? Experimente este jogo onde uma multidão de jogadores se reúne em volta de uma mesa para fazer apostas. Faça suas previsões de vitória apostando em um ou vários lados!',
  gameDescribe113:
    'Um jogo clássico de Banker Bull-Bull. Agarre a chance de ser o banqueiro e varrer o baralho?',
  gameDescribe115:
    'Uma nova variante popular do bem conhecido clássico Banker Bull-Bull. Três de cinco cartas serão reveladas no início de cada mão. Tendo todas essas informações à sua disposição, como você decidirá o que fazer a seguir?',
  gameDescribe117:
    'Uma variante mais simples de jogos de cartas do banqueiro que é fácil de aprender. Venha e teste a sua sorte neste jogo de sorte!',
  gameDescribe126:
    'Uma nova variante popular do clássico bem conhecido "Banker Bull-Bull". Quatro das cinco cartas serão reveladas no início de cada mão. Tendo todas essas informações à sua disposição, como você decidirá o que fazer a seguir?',
  gameDescribe201:
    'Houve uma vez um Sapo Dourado milenar que cuspia dinheiro, e rumores dizem que ele está para retornar à terra dos vivos. Você está pronto para saquear todos os tesouros do mar?',
  gameDescribe202:
    'O Deus da Riqueza chegou, trazendo riqueza e tesouro. Mísseis de perfuração imparáveis são lançados, munições de metralhadora se acumulando rapidamente até atingir a capacidade máxima de 999. Venha e demonstre seu poder!',
  gameDescribe203:
    'A lenda diz que uma vez que você coleta todas as sete bolas do dragão, você pode invocar o Imperador Dragão e fazer um desejo. O Imperador Dragão foi avistado nos mares profundos, e com ele a promessa de abundância e boa sorte.',
  gameDescribe204:
    'As melodias etéreas das canções das sereias flutuam sobre o mar à meia-noite, e a promessa de um incrível tesouro está no fundo do mar... Naveguem, bravos aventureiros! Vamos explorar a Lenda da Sereia!',
  gameDescribe205:
    'Uma batalha entre lendas. Os melhores atiradores cercam e capturam a fortaleza do inimigo. Quem vai superar e dominar a Copa do Mundo? Experimente a loucura do futebol com o World Cup Shooting da GFG!',
  gameDescribe301:
    'Não apenas atrai riqueza, mas também traz boa sorte! Rodadas extras acionadas com duas bobinas correspondentes na linha aumentam significativamente sua taxa de vitória! Gire as bobinas da fortuna e veja seus ganhos se multiplicarem até um máximo de 888 vezes!',
  gameDescribe302:
    'O slot de Ano Novo Chinês da GFG! A roda da boa fortuna gira. Multiplique seus ganhos com wilds especiais até um máximo de 3000X! Ouça o chamado do gongo da fortuna! Desejamos a todos um ano próspero de sucesso!',
  gameDescribe303:
    'Clinkety-clink, ouça o tilintar de moedas chovendo! GFG convida você a celebrar esta temporada festiva com a Missão de Natal! Colete 3 Trenós da Fortuna e ganhe instantaneamente pagamento básico mais jogo grátis! Ganhe até 200 rodadas grátis!',
  gameDescribe304:
    'GFG - Wang Lai trará sorte e riqueza para você! Multiplique seus ganhos com símbolos selvagens em Wang Lai! Colete lanternas da fortuna e ganhe instantaneamente no mínimo 8 rodadas grátis. Ganhe até 99 jogos grátis consecutivos adicionais!',
  gameDescribe305:
    'Mahjong Win - uma quintessência da cultura nacional - está chegando ao GFG! 3 símbolos de vitória com autopego acionam 12 rodadas grátis. Ganhe até 10 vezes o bônus com eliminação contínua! Venha tentar a sua sorte e torne-se o próximo Rei do Mahjong!',
  gameDescribe306:
    'GFG passa todas as noites com você. Coletar três meteoros para trazer uma chuva de meteoros. Com o mundo isolado do barulho de AKQJ, só restam mais gemas. Banhe-se em suas conquistas e mergulhe em um sonho.',
  gameDescribe307:
    'Triple 7, o número mais mágico. Clássica máquina de caça-níquel de uma alavanca 1x3: aposte com facilidade e ganhe vastas riquezas. Multiplique sua recompensa por incríveis 100 vezes. Teste sua sorte e traga para casa as bênçãos ultrajantes do triplo 7.',
  gameDescribe308:
    'Está se sentindo com sorte? Escolha o seu número favorito e pressione iniciar. Deixe girar para a vitória ao alcance das mãos. Ganhe uma fortuna com uma combinação simples e aleatória de dígitos. Quem disse que você não pode ganhar um grande prêmio com uma clássica máquina de caça-níquel de uma alavanca?',
  gameDescribe309:
    'Entre todos os tipos de máquinas caça-níqueis, Buffalo é uma verdadeira lenda. Sempre foi o mais popular em Las Vegas. Agora Buffalo está prestes a dominar a GFG, levando você a uma jornada para fazer uma grande fortuna com sua efervescência.',
  gameDescribe310:
    'Está pronto para revelar os segredos no Livro dos Mortos? Abra o portão para o tesouro secreto no Egito com 3 Livros dos Mortos. Ganhe até 200 jogos grátis. O sonho de possuir riquezas eternas pode se tornar realidade.',
  gameDescribe311:
    'Este é a terra prometida da abundância. Todos têm a oportunidade de acelerar ao máximo. Ganhe a chance de passar de trapos a riquezas com as suas próprias mãos. Lute! Lute pela glória absoluta e fortunas com sangue, suor e lágrimas.',
  gameDescribe313:
    'A sagrada e nobre besta auspiciosa - Longlonglong. A máquina de slot 1X3 mais clássica está de volta na GFG, com a chance de ganhar um prêmio 100 vezes maior! Obtenha a sorte da bênção do dragão agora, e todos que você vê obterão uma grande riqueza!',
  gameDescribe320:
    'Adornada por Espadas, Copas, Ouros e Paus. Uma paisagem deslumbrante entrelaçada por Ás, Rei, Rainha e Curinga. Neste mundo de fantasia composto por 52 cartas, aquele que tem o trunfo supremo detém o segredo das riquezas abundantes.',
  gameDescribe323:
    'Nesta terra de mistério, o segredo da imortalidade e riquezas incontáveis estão à sua espera. Venha jogar o Pokey, recém-lançado pela GFG, para validar o seu convite para a terra celestial. Ganhe as suas fortunas sem fim com três gongos dourados.',
  gameDescribe324:
    'Lembra-se das moedas preciosas nas nossas poupanças quando éramos jovens? A clássica Slot GFG 1X3 vai realizar os seus sonhos de infância. Venha e veja os seus ganhos multiplicarem até um máximo de 888 vezes.',
  gameName320: 'Super Ás',
  qw: 'CERTIFICAÇÃO OFICIAL',
  qw_des:
    'A GFG é a principal fornecedora de jogos do mundo. Ao derramar nosso entusiasmo e inovação, estilos diversificados, efeitos sonoros comoventes, performances atraentes e mecanismo de jogo justo, integração perfeita em nosso jogo. Integre a alegria na vida e forneça aos clientes a melhor experiência de entretenimento.',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'Vídeo promocional',
  fishname: 'Jogo de Pesca em Alta',
  fishname2: `Bem-vindo ao mundo submarino do jogo de pesca em alta!<br/>
  Aqui, diversão e desafios estão em todo lugar!<br/>
  Junte-se a nós nesta emocionante aventura de pesca!`,
  languageChange: 'Língua'
}
