import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n/index';
import EasySlider from "vue-easy-slider";
import seamless from 'vue-seamless-scroll'
import "./assets/style/reset.css"
import "./assets/style/common.less"
import global_ from './components/tool/Global.js'
import "./assets/fonts/font.css"
import VueNumber  from 'vue-number-animation';
import animate from "animate.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(animate);
Vue.use(VueNumber)
Vue.use(ElementUI);

// PC和mobile端：根据isMobile判断不同的终端，使用不同的路由
/* const isMobile =()=> {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}
 */
Vue.use(router)

Vue.use(seamless)


import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

// 或者你可以自己设置全局注册的组件名 默认注册的组件名是 vue-seamless-scroll
Vue.use(seamless,{componentName: 'scroll-seamless'})
Vue.prototype.GLOBAL = global_

Vue.use(EasySlider);
Vue.config.productionTip = false

// const skin = global_.getDomainConfigure().skin || 'skin1'
// localStorage.setItem('skin', skin)
// console.log(skin, 11111);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

