export default {
  difference: '不凡',
  makes: '创造非凡',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst:'最新游戏',
  games_BoxLast:'罗马竞技场 ',
  newGames:'热门游戏',
  games: '游戏展示',
  games_more:'了解更多',
  games_newdes:'各式游戏、多元主题、多样玩法，我们致力于提供客户最极致的游戏体验。',
  games_des: '多变玩法、绚丽画面、独具匠心的故事情节,<br/>每一刻都是全新探索与惊喜，感受前所未有的游戏体验！',
  games_newspan:'所有游戏',
  aboutUstext1:'关于我们',
  aboutUstext2:'GFG大运电子是全球顶尖的游戏供应商。热情，让我们把各地的专家组成合作无间的团队。责任，让我们将公平游戏视为开发的最高准则。无限可能，让我们创造出天马行空的GFG游戏世界。',
  aboutUstext3:'GFG大运电子是全球顶尖的游戏供应商。<br/>热情，让我们把各地的专家组成合作无间的团队。<br/>责任，让我们将公平游戏视为开发的最高准则。<br/>无限可能，让我们创造出天马行空的GFG游戏世界。',
  mainData_h2:'1',
  mainData_p:'单一API',
  mainData_span:'只需一个API就可以完美对接棋牌、老虎机、捕鱼等精彩游戏。',
  mainCenter_h2:'5',
  mainCenter_p:'五大装置兼容',
  mainCenter_span:'游戏支援IOS/Android/Windows/MacOS/HTML5。',
  mainLanguage_h2:'10',
  mainLanguage_p:'支持十种语言',
  mainLanguage_span:'我们致力于让玩家使用自己的母语，更好地了解游戏内容、融入游戏、享受更自在的游戏体验。',
  mainSafety_h2:'24',
  mainSafety_p:'二十四小时服务在线',
  mainSafety_span:'我们不只是提供游戏，还有客服团队全天候待命，为您提供最极致的服务。',
  about: '合作伙伴',
  about_des: '亞洲最佳合作伙伴，事业版图扩及东亚、东南亚、中国、日本、韩国 、越南、泰国、印度尼西亚及马来西亚等地区。',
  vision: '联络我们',
  vision_des: '运营商、代理商、包网提供商 若您有意对接我们的产品， 欢迎您与我们联络，将有专人为您提供服务。',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'COPYRIGHT © GOOD FORTUNE GAMING ALL RIGHTS RESERVED.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1:'最新消息',
  nav_All:'全部消息',
  nav_Latest:'最新游戏',
  nav_Preview:'精彩活动',
  nav_Maintain:'维护公告',
  hot_BoxFirst:' ',
  hot_BoxLast:'罗马竞技场',
  //游戏大全
  games_h1:'游戏',
  navAll:'全部游戏',
  navFish:'鱼机',
  navChess:'棋牌',
  navSlot:'虎机',
  allgames_BoxFirst:' ',
  allgames_BoxLast:'罗马竞技场',
  // 游戏内页
  inner1:'首页',
  inner2:'游戏介绍',
  inner3:'游戏规格',
  inner4:'游戏名称',
  inner5:'游戏类型',
  inner6:'游戏震幅',
  inner7:'中等的',
  inner8:'RTP',
  inner9:'最大倍率',
  inner10:'全尺寸',
  inner11:'初始下载',
  inner12:'兼容',
  inner13:'支援语系',
  gameName5:'红黑大战',
  gameName9:'百家乐',
  gameName10:'百人牛牛',
  gameName11:'龙虎斗',
  gameName12:'炸金花',
  gameName14:'抢庄二八杠',
  gameName18:'二十一点',
  gameName22:'抢庄牌九',
  gameName25:'通比牛牛',
  gameName27:'癞子牛牛',
  gameName29:'免佣百家乐',
  gameName32:'超级百人牛牛',
  gameName113:'新抢庄牛牛',
  gameName115:'新看三张抢庄牛牛',
  gameName117:'新抢庄三公',
  gameName126:'新看四张抢庄牛牛',
  gameName201:'金蟾捕鱼',
  gameName202:'财神捕鱼',
  gameName203:'神龙捕鱼',
  gameName204:'人鱼传说',
  gameName205:'世足争霸',
  gameName301:'貔貅开运',
  gameName302:'大运夺宝',
  gameName303:'圣诞任务',
  gameName304:'旺来',
  gameName305:'麻将无双',
  gameName306:'宝石之星',
  gameName307:'777',
  gameName308:'三倍小丑',
  gameName309:'水牛',
  gameName310:'死亡之书',
  gameName311:'罗马竞技场',
  gameName313:'龙龙龙',
  gameName320:'超级王牌',
  gameName323:'仙境探宝',
  gameName324:'招财金猪',
  gameDescribe5:'开局便可知道红黑两阵营的两张手牌，根据你的直觉与胆识，决定谁是胜利的一方。',
  gameDescribe9:'源自于义大利的经典牌桌游戏百家乐，是世界各地赌场最受欢迎的游戏之一。面对千变万化的牌型组合，你决定使用甚麽策略?还是直接赌上运气，靠直觉做出选择?',
  gameDescribe10:'传统的抢庄牛牛玩腻了吗? 试试旁观者外围下注玩法，妳可以选择一个甚至多个你喜欢的位置，预测他的胜利。',
  gameDescribe11:'如同免佣百家一般，押龙押虎，胜者可全拿，庄家作壁上观。龙长吟，虎狂啸，一张定生死。',
  gameDescribe12:'中国广泛流传，逢年过节会玩几把的的一种民间多人纸牌游戏，面对对手未知的手牌，这次的下注，跟还是不跟?比还是不比?每个回合都在考验你的胆略与智慧来一把炸金花吧！',
  gameDescribe14:'源自于中国长江三角洲沿海省份的民间扑克游戏，从第一步翻牌决定庄家，变开始了策略与运气的对决。',
  gameDescribe18:'300多年前缘起于法国的扑克牌游戏，有限的牌型数量，考验你的记忆力、分析力、计算出可能的机率后，剩下的就交给老天吧!',
  gameDescribe22:'韦小宝最爱的骨牌游戏结合扑克抢庄玩法，更高的倍数、更多的局数，享受不同的新体验。',
  gameDescribe25:'只需要选择一次倍数，就能一较高下，胜者通吃全杀，败者只输一人，以小搏大的好机会。',
  gameDescribe27:'在原本经典的抢庄扑克游戏中，增加了大王小王的新元素，能够取代任意牌型的强大功能，增加了手牌千变万化的可能。',
  gameDescribe29:'游戏规则直接捨弃了原本百家乐押庄赢钱收取佣金的机制，押庄赢了不用被抽佣金可以全拿回来的感觉，你一定要试一次。',
  gameDescribe32:'传统的抢庄牛牛玩腻了吗? 试试旁观者外围下注玩法，妳可以选择一个甚至多个你喜欢的位置，预测他的胜利。',
  gameDescribe113:'經典的棋牌遊戲搶莊牛牛，搶莊準備一舉通吃?',
  gameDescribe115:'抢庄牛牛广受欢迎的新颖玩法，在要你做任何决定之前，便大胆的提早开出五张中的三张手牌，得到这麽多资讯的你，该如何决定下一步呢?',
  gameDescribe117:'简化版本的抢庄扑克游戏，让你更快了解游戏，更纯粹的比较运气，快来试试吧。',
  gameDescribe126:'抢庄牛牛广受欢迎的新颖玩法，在要你做任何决定之前，便大胆的提早开出五张中的四张手牌，得到这麽多资讯的你，该如何决定下一步呢?',
  gameDescribe201:'曾经有一只会吐金钱的千年金蟾，如今即将再次重返人间，能一网打尽所有鱼群的神奇渔网、散发神秘紫光的深水炸弹，你准备好一网打尽所有的宝藏了吗?',
  gameDescribe202:'财神到、财神到、带着满满的财宝，欢迎各路豪杰备妥武器来发财，钻头炮弹，前进突刺，谁也拦不住；机关子弹，疯狂累积，凑满999；等你大显神威！',
  gameDescribe203:'传说中只要你搜集了七颗龙珠，便能向神龙许一个愿望。龙神行大运，深海现踪迹，一声龙吟，直穿云霄；定海神针，横扫波滔。你，见到神龙了吗?',
  gameDescribe204:'某片海域流传着许多神秘传说，雾起漂荡的幽灵船；夜半飘渺的人鱼歌；以及海洋最深处的巨额宝藏...启程吧，勇敢的冒险者亲自去探究人鱼传说！',
  gameDescribe205:'群雄争胜，顶级射手为球队攻城拔寨，谁能突围成功，称霸世界杯的舞台？GFG《世足争霸》陪您一起身临其境，感受世足狂热。',
  gameDescribe301:'GFG最新招财兽－「貔貅开运」不只能招财，还能带来好运气！经典单线游戏让您好运连环！双轴相同，额外翻盘，胜率大增！物件满盘，招财转轮，倍数翻倍，最高888倍！快来试试您的手气，把貔貅好运带回家！',
  gameDescribe302:'新春报喜，招财福运来报到！GFG同名迎春虎机－「大运夺宝」让您行大运，夺巨宝！经典243路游戏，轻松中奖！鸿运转轮，百搭降临，倍数倍增，最高3000倍！敲响贺岁铜锣，幸运夺得8场以上免费游戏奖赏，最高有机会可获得99场！「大运夺宝」迎新年，春风得意一整年！',
  gameDescribe303:'叮叮当，叮叮当，满屏金币多响亮！大运电子欢乐巨献－圣诞任务，邀您一起欢庆佳节！欢喜财富翩然来到，圣诞老人亲送五连线高赔率大奖！搜集3个财富雪橇，立即夺得基本奖赏与免费游戏奖赏！最高还可获得200场！圣诞任务给您带来幸福，将佳节大礼送到家！',
  gameDescribe304:'传说有种神奇的果实，拥有它鸿运滚滚而来！大运电子开运老虎机－「旺来」为您添好运，招大财！经典243路游戏，旺来百搭再翻倍，让您中奖再加码！搜集福运天灯，立刻享有8场以上免费游戏奖赏，最高可连续畅玩99场！立刻喜迎旺来，让您福来运来旺旺来！',
  gameDescribe305:'千年国粹的精华-麻将无双，即将在大运电子重现！这款经典消除类老虎机，中奖后奖图自动转成百搭符号，搭配1024 Ways让你得分机率大暴增。3 个自摸出现，立即赢得 12 次免费游戏，每个额外的自摸图案另可触发 2 次免费游戏！高达10倍的连消加成，让你连庄拉庄，杠上开花发更发想成为新一代麻将之王吗？现在就来试试自己的手气吧！',
  gameDescribe306:'满天星斗落入凡尘，化作一颗颗耀眼的宝石，GFG老虎机，陪你一起搜集、度过每一个夜晚出现三颗流星，便会召唤流星雨，世界隔绝了AKQJ的喧闹，只有宝石，更多的宝石!然后带着满满的收获，沉浸在璀璨的梦乡。',
  gameDescribe307:'传说7是最神奇的数字，给每个人带来幸运加持！结合1X3的经典虎机，轻松押注，轻松中奖最高可获得100倍大奖！现在就下场试试您的手气，再把777的好运带回家！',
  gameDescribe308:'感觉到炙热的获胜欲望了吗？选个喜欢的数字、催动开关，为即将到手的胜利疯狂旋转简单排列、任意组合都能带来丰厚进帐谁说经典老虎机不能赢得超级大奖呢？',
  gameDescribe309:'如果老虎机世界中有真正的传奇，那一定是Buffalo多年来稳坐拉斯维加斯最受欢迎的第一名现在Buffalo霸气降临GFG以它的凶猛冲劲，为您撞破财富闸门',
  gameDescribe310:'准备好揭开死亡之书的秘密了吗？传说中里面不但藏着永生的秘密，还有历代法老财富的寻宝线索获取3本死亡之书，即刻开启埃及秘宝的大门！最高有机会免费加码200场！让您实现永生富裕的梦想！',
  gameDescribe311:'这是拥有丰盛飨宴的应许之地人人都有全力拼搏的机会用双手撕扯出自己翻身的契机奋战吧！用鲜血与生命换取绝对的光荣与财富',
  gameDescribe313:'神圣崇高的吉兽-龙龙龙，为每个人带来千年的祥和与平安！最经典1X3的虎机，即将在GFG重现还有机会获得百倍大奖！现在就领取神龙加持的幸运，见到的人都会获得巨额财富吧！',
  gameDescribe320:'黑桃、红心、方块跟梅花妆点这里的每个角落Ace、King、Queen、Joker交织出华美风貌在这个52张纸牌组成的魔幻世界里谁拿到超级王牌，谁就掌握真正的财富密码',
  gameDescribe323:'仙境之中，除了长生不老的秘密，就是享用不完的巨额财宝了GFG隆重推出仙境探宝老虎机，邀请您进入天宫秘境获得3个金锣，便能坐拥无尽财富现在就推开梦想的大门，体验快活似神仙的感受',
  gameDescribe324:'还记得我们小时候把舍不得用的零花钱铜板一个个无比珍惜地放进心爱的小猪扑满里GFG招牌1X3老虎机，奖金最高888倍，实现您的童年梦想帮您轻松中大奖，让钱变成您最喜欢的样子',
  footText:'COPYRIGHT © GOOD FORTUNE GAMING ALL RIGHTS RESERVED.',
  gameType_chess:'棋牌',
  gameType_fish:'鱼机',
  gameType_slot:'虎机',
  gameSystem_Android:'安卓',
  gameSystem_Windows:'视窗',
  gameSystem_MacOS:'苹果系统',
  qw: '官方认证',
  qw_des:'GFG 是世界上顶尖的游戏供应商，我们提供多样化的风格、动听的音效、吸引人的表演和公平的游戏机制，完美融入我们的游戏中，将乐趣带入生活，为客户提供最佳的娱乐体验。',
  qw_1: 'MGA 是一个单一且独立的监管机构，负责管理马耳他的所有博彩活动，确保行业内有效、全面的立法框架和公司结构。',
  qw_2: 'BMM Testlabs 是一家私人独立游戏测试实验室，成立于 1981 年 11 月 27 日。获得 ISO 17025 IT 和 17020 Inspection Bodies 认证，在 400 多个司法管辖区得到认可，在 13 个国家/地区设有 14 个办事处。',
  qw_3: '博彩委员会是一个独立委员会，旨在与许可当局合作，在英国许可和监管商业博彩。由数字、文化、媒体和体育部赞助的公共机构赌博委员会 (DCMS) 为被许可人制定要求并进行评估以确保合规。',
  qw_4: 'Gaming Associates 是一家独立且国际认可的认可测试机构 (ATF)。作为一家通过 ISO 9001、ISO/IEC 17025 和 PCI QSA 认证的公司，它得到了全球权威机构的认可。',
  videgames: '动感震撼',
  fishname: '爆款捕鱼',
  fishname2: `欢迎来到爆款捕鱼的海底世界！<br/>
  在这里，乐趣和挑战无处不在！<br/>
  和我们一起开启这段令人兴奋的海底之旅吧！`,
  languageChange: '语言切换'
}