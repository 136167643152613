export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'Últimos jogos',
  games_BoxLast: 'रोमा ',
  newGames: 'लोकप्रिय खेल',
  games: 'गेम डिस्प्ले',
  games_more: 'और अधिक सीखें',
  games_newdes:
    'विभिन्न खेल, विविध थीम्स और कई गेमप्ले। हम अपने ग्राहकों को अंतिम गेमिंग अनुभव प्रदान करने का प्रयास करते हैं।',
  games_des:
    'เกมเพลย์หลากหลาย, ภาพกราฟิคสวยงาม, และเนื้อเรื่องที่ถูกสร้างขึ้นด้วยความเป็นเอกลักษณ์;ทุกๆ ลม breath คือการสำรวจใหม่และความประหลาดใจ, มาสัมผัสประสบการณ์เล่นเกมที่ไม่เคยมีมาก่อน!',
  games_newspan: 'सभी खेल',
  aboutUstext1: 'हमारे बारे में',
  aboutUstext2:
    'GFG विश्व के सर्वश्रेष्ठ गेमिंग प्रदाता है। उत्कटता वह विचार है जो पूरी दुनिया के विशेषज्ञों को एक सहकारी टीम बनाने के लिए संगठित करता है। जवाबदेही ही है जिसके कारण हम अपने गेम विकास में न्यायपूर्वक खेल को सबसे उच्च प्राथमिकता देते हैं। अनंत संभावनाएं ही हैं जिन्होंने जीएफजी गेम वर्ल्ड को निर्मित किया है - एक पूर्ण कल्पना का एक ऐसा विश्व।',
  aboutUstext3:
    'GFG विश्व के सर्वश्रेष्ठ गेमिंग प्रदाता है। उत्कटता वह विचार है जो पूरी दुनिया के विशेषज्ञों को एक सहकारी टीम बनाने के लिए संगठित करता है। जवाबदेही ही है जिसके कारण हम अपने गेम विकास में न्यायपूर्वक खेल को सबसे उच्च प्राथमिकता देते हैं। अनंत संभावनाएं ही हैं जिन्होंने जीएफजी गेम वर्ल्ड को निर्मित किया है - एक पूर्ण कल्पना का एक ऐसा विश्व।',
    mainData_h2: '1',
  mainData_p: 'एकल API',
  mainData_span:
    'केवल एक API की आवश्यकता होती है ताकि आप चौपट, स्लॉट मशीन, मछली पकड़ने जैसे शानदार खेलों को पूरी तरह से एकसंगत कर सकें।',
  mainCenter_h2: '5',
  mainCenter_p: '५ उपकरणों के साथ संगतित',
  mainCenter_span:
    'यह खेल IOS / Android / Windows / MacOS / HTML5 का समर्थन करता है।',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 भाषाओं का समर्थन किया गया',
  mainLanguage_span:
    'हम खिलाड़ियों को अपनी मातृभाषा का उपयोग करने की सुविधा प्रदान करने के लिए समर्पित हैं ताकि वे सामग्री को बेहतर ढंग से समझ सकें, खेलों में डूब सकें और मोहक गेमप्ले का आनंद ले सकें।',
  mainSafety_h2: '24',
  mainSafety_p: '24 घंटे ऑनलाइन सेवा',
  mainSafety_span:
    'हम न केवल खेल प्रदान करते हैं, बल्कि 24 घंटे चलने वाली ग्राहक सेवा टीम भी हमारे पास है, जो आपको सबसे अधिकाधिक सेवा प्रदान करेगी।',
  about: 'साझेदार',
  about_des:
    'व्यापार में एशिया का सर्वश्रेष्ठ साझेदार, पूर्वी एशिया, दक्षिणपूर्व एशिया, चीन, जापान, दक्षिण कोरिया, वियतनाम, थाईलैंड, इंडोनेशिया और मलेशिया में मौजूद है।',
  vision: 'हमसे संपर्क करें',
  vision_des:
    'ऑपरेटर, एजेंट और नेटवर्क प्रदाता। यदि आप हमारे उत्पादों में रुचि रखते हैं, हमसे संपर्क करने के लिए स्वतंत्र महसूस करें। हम जितनी जल्दी हो सके आपके पास वापस आएंगे।',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'कॉपीराइट © गुड फोर्चुन गेमिंग सभी अधिकार सुरक्षित है।',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'नवीनतम समाचार',
  nav_All: 'सभी',
  nav_Latest: 'नवीनतम खेल',
  nav_Preview: 'घटनाएँ',
  nav_Maintain: 'रखरखाव',
  hot_BoxFirst: '',
  hot_BoxLast: 'रोमा',
  //游戏大全
  games_h1: 'खेल',
  navAll: 'सभी',
  navFish: 'मछली पकड़ना',
  navChess: 'बोर्ड गेम्स',
  navSlot: 'स्लॉट्स',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'रोमा',
  // 游戏内页
  inner1: 'होमपेज',
  inner2: 'गेम परिचय',
  inner3: 'गेम स्पेसिफिकेशन',
  inner4: 'गेम का नाम',
  inner5: 'गेम के प्रकार',
  inner6: 'गेम तीव्रता',
  inner7: 'मध्यम',
  inner8: 'रियल-टाइम ट्रांसमिशन प्रोटोकॉल',
  inner9: 'मान्यता अधिकतम',
  inner10: 'पूर्ण आकार',
  inner11: 'प्रारंभिक डाउनलोड',
  inner12: 'संगतता',
  inner13: 'समर्थित भाषाएं',
  //游戏名称
  gameName5: 'लाल बनाम काला',
  gameName9: 'बैकारेट',
  gameName10: 'बुल-बुल',
  gameName11: 'ड्रैगन टाइगर गेम',
  gameName12: 'गोल्डन फ़्लॉवर',
  gameName14: 'बैंकर दिस बार',
  gameName18: 'ब्लैकजैक',
  gameName22: 'बैंकर पाई गाव',
  gameName25: 'कैसिनो बुल-बुल',
  gameName27: 'बुल-बुल वाइल्ड',
  gameName29: 'बिना कमीशन वाला बैकारेट',
  gameName32: 'सुपर बुल-बुल',
  gameName113: 'बैंकर बुल-बुल',
  gameName115: 'थ्री कार्ड्स अप बैंकर बुल-बुल',
  gameName117: 'बैंकर थ्री फ़ेसकार्ड',
  gameName126: 'फ़ोर कार्ड्स अप बैंकर बुल-बुल',
  gameName201: 'गोल्डन टोड फ़िश',
  gameName202: 'गॉड ऑफ़ वेल्थ फ़िश',
  gameName203: 'ड्रैगन',
  gameName204: 'जलपरी की कहानी',
  gameName205: 'वर्ल्ड कप शूटिंग',
  gameName301: 'लकी पिशु',
  gameName302: 'अच्छी किस्मत',
  gameName303: 'क्रिसमस मिशन',
  gameName304: 'रत्न',
  gameName305: 'महजोंग विन',
  gameName306: 'रत्न',
  gameName307: '777',
  gameName308: 'जोकर ट्रिपल',
  gameName309: 'बफ़ेलो',
  gameName310: 'बुक ऑफ़ डेथ',
  gameName311: 'रोमा',
  gameName313: 'लॉन्ग लॉन्ग लॉन्ग',
  gameName323: 'वंडरलैंड ट्रेज़र',
  gameName324: 'पिगी फ़ॉर्च्युन',
  gameDescribe5:
    'प्रत्येक गेम की शुरुआत में प्रत्येक पक्ष (लाल या काले) के तीन कार्डों में से दो का खुलासा किया जाएगा। अपनी सहजता और बुद्धि का पालन करके एक विजयी पक्ष चुनें!',
  gameDescribe9:
    'दुनिया भर में सबसे लोकप्रिय कैसीनो खेलों में से एक। विभिन्न संभव कार्ड संयोजनों के साथ, आप कौन सी रणनीति लागू करेंगे? क्या यह भाग्य का जुआ होगा, या एक सहज चयन?',
  gameDescribe10:
    'क्या आप Banker Bull-Bull खेलने से थक चुके हैं और चीजों को मसालेदार बनाना चाहते हैं? इस खेल का प्रयास करें जहां खिलाड़ियों का एक समूह टेबल के चारों ओर बैठकर शर्तें लगाता है। एक या एकाधिक पक्षों पर शर्त लगाकर अपनी विजय की भविष्यवाणी करें!',
  gameDescribe11:
    'No Commission Baccarat के समान। ड्रैगन पर शर्त लगाएं, टाइगर पर शर्त लगाएं। विजेता सब कुछ लेता है, जबकि बैंकर दीवार पर देख रहा होता है।',
  gameDescribe12:
    'अपने प्रतिद्वंद्वियों के सामने जिनके हाथ का रहस्य अनकठा है, आपको उनके दांव के अनुसार फैसला लेना होगा। कॉल करें या नहीं करें? प्रत्येक दौर आपकी दृढ़ता, होशियारी, और बुद्धि को परम कसौटी पर लाता है।',
  gameDescribe14:
    'एक लोकप्रिय कार्ड खेल जो चीन के यांगज़ी नदी डेल्टा क्षेत्र के तटीय प्रांतों से उद्भवित हुआ है। एक कार्ड की चपेट में बैंकर तय हो जाने के बाद, रणनीति और भाग्य की लड़ाई शुरू हो जाती है।',
  gameDescribe18:
    'संभाव्य संयोजनों की सीमित संख्या वाला एक कार्ड खेल, संभावनाओं की गणना करते समय आपके स्मृति, विश्लेषणात्मक और गणना कौशल का परीक्षण करता है। अपनी सर्वश्रेष्ठ करें और बाकी को भाग्य पर छोड़ दें!',
  gameDescribe22:
    'Wei Xiaobao का पसंदीदा डोमिनो खेल खेलें, बैंकर बनने का मौका पकड़ने के साथ। अतिरिक्त राउंड और उच्च गुणक के साथ नए अनुभव में खुद को खोयें!',
  gameDescribe25:
    'प्रत्येक खेल की शुरुआत में एक एकल गुणक राशि चुनें और एक शोडाउन के लिए तैयार हो जाएं। विजेता सभी को ले जाता है, और हारने वाला केवल विजेता को भुगतान करता है। यह आपका अवसर है छोटा शर्त लगाने और बड़ा जीतने का।',
  gameDescribe27:
    'इस क्लासिक बैंकर कार्ड गेम में जोकर शामिल हैं ताकि एक जंगली कार्ड तत्व को जोड़ा जा सके। वे उनके धारक द्वारा उनके पसंद के किसी भी कार्ड के रूप में निर्दिष्ट किए जा सकते हैं, इस प्रकार संभावित कार्ड संयोजनों में एक अतिरिक्त आश्चर्य तत्व को जोड़ देते हैं।',
  gameDescribe29:
    'नो कमीशन बैकरेट बैंकर हाथ जीत पर कमीशन नहीं लेता है। नो कमीशन बैकरेट के साथ समान भुगतान प्राप्त करने की जल्दबाजी महसूस करें!',
  gameDescribe32:
    'क्या आप Banker Bull-Bull खेलने से थक चुके हैं और चीजों को मसालेदार बनाना चाहते हैं? इस खेल को आज़माएं जहां एक भीड़ खिलाड़ी बेट लगाने के लिए एक टेबल के चारों ओर जमा होती है। एक या कई पक्षों पर शर्त लगाकर अपनी विजय की भविष्यवाणी करें!',
  gameDescribe113:
    'बैंकर बुल-बुल का एक क्लासिक खेल। बैंकर बनने का मौका पकड़ें और डेक को साफ कर दें?',
  gameDescribe115:
    'अच्छी तरह से जाने जाने वाले क्लासिक Banker Bull-Bull का एक लोकप्रिय नया वैरिएंट। प्रत्येक हाथ की शुरुआत में पांच कार्डों में से तीन का खुलासा होगा। जब आपके पास सभी यह जानकारी हो, तो आप अगला क्या करने का निर्णय कैसे लेंगे?',
  gameDescribe117:
    'बैंकर कार्ड खेलों का एक सरल रूप जिसे सीखना आसान है। इस भाग्य खेल में अपनी भाग्य आजमाएं!',
  gameDescribe126:
    'अच्छी तरह से जाने जाने वाले क्लासिक "Banker Bull-Bull" का एक लोकप्रिय नया संस्करण। प्रत्येक हाथ की शुरुआत में पांच कार्डों में से चार का खुलासा होगा। सभी जानकारी के साथ अपने उंगलियों के छुए, आप अगला क्या करने का निर्णय कैसे लेंगे?',
  gameDescribe201:
    'एक बार एक हजार वर्ष पुराना स्वर्ण टोड़ था जो पैसे उगलता था, और अफवाह है कि वह जीवित लोगों की भूमि पर वापस लौटने वाला है। क्या आप समुद्र के सभी खजानों को लूटने के लिए तैयार हैं?',
  gameDescribe202:
    'धन के भगवान आ गए हैं, धन और खजाना लेकर। रुकने वाले नहीं ड्रिल मिसाइल प्रक्षेपित होते हैं, मशीन गन की गोलियां तेजी से संचित हो रही हैं जब तक पूरी गोली 999 नहीं हो जाती। आओ और अपनी क्षमता का प्रदर्शन करो!',
  gameDescribe203:
    'मान्यता है कि एक बार जब आप सात ड्रैगन बॉल्स को एकत्र कर लेते हैं, तो आप ड्रैगन सम्राट को बुला सकते हैं और एक इच्छा कर सकते हैं। ड्रैगन सम्राट को गहरी समुद्रों में देखा गया है, और इसके साथ समृद्धि और सौभाग्य का वादा।',
  gameDescribe204:
    'मिडनाइट के समय समुद्र के ऊपर मरमेड गीतों के आध्यात्मिक सुर बहते हैं, और एक अविश्वसनीय खजाने का वादा समुद्र की तलहटी में है... बहादुर साहसियों, सेल सेट करो! चलो हम मरमेड की कथा का अन्वेषण करें!',
  gameDescribe205:
    'लड़ाई का महासंघर्ष। शीर्ष शूटर बंदी करते हैं और दुश्मन का क़िला हथिया लेते हैं। कौन सबसे आगे बढ़कर विश्व कप को वश में करेगा? GFG के विश्व कप शूटिंग के साथ फुटबॉल के महकते हुए माहौल का अनुभव करें!',
  gameDescribe301:
    'धन खींचने के साथ-साथ यह भलाई का भी वरदान दे सकता है! पंक्ति में दो मेल रील्स के साथ अतिरिक्त स्पिन्स को सक्रिय करके आपकी जीत दर को बहुत बढ़ाता है! भाग्य की रील्स को घुमाएं और अधिकतम 888 बार तक अपनी जीत को बढ़ाएं!',
  gameDescribe302:
    'GFG का चाइनीज न्यू इयर स्लॉट! भाग्य का चक्र घूमता है। खास वाइल्ड के साथ अपनी जीत को 3000X तक बढ़ाएँ! भाग्य की घंटी की आवाज़ सुनें! चीनी नए साल का स्वागत करें, सभी को सफलता का समृद्ध वर्ष की कामना करते हैं!',
  gameDescribe303:
    'झनझनाहट, सिक्कों की बौछार की आवाज सुनिए! GFG आपको Xmas मिशन के साथ इस त्योहारी मौसम का जश्न मनाने के लिए आमंत्रित करता है! 3 फोर्च्यून स्लेड्स को इकट्ठा करें और तत्काल बेस पे और मुफ्त खेल जीतें! 200 मुफ्त स्पिन तक जीतें!',
  gameDescribe304:
    'GFG - वांग लाई आपको भाग्य और धन लाएगा! वांग लाई में वाइल्ड के साथ अपनी जीत को बढ़ाएं! धन की लालटेन एकत्र करें और तत्काल में कम से कम 8 मुक्त घूम जीतें। अतिरिक्त लगातार 99 मुक्त खेल तक जीतें!',
  gameDescribe305:
    'माझांग विन - राष्ट्रीय संस्कृति का एक महत्वपूर्ण हिस्सा - GFG में आ रहा है! 3 स्वयं-खींचने वाले जीत के प्रतीकों से 12 मुक्त घूम जाते हैं। निरंतर छुटकारे के साथ बोनस का 10 गुना जीतें! अपनी किस्मत की कोशिश करें और अगले माझांग राजा बनें!',
  gameDescribe306:
    'GFG रात को हर बार आपके साथ बिताता है। तीन उल्का इकट्ठा करें और एक उल्का बौछार लाएं। AKQJ के शोर से अलग दुनिया में, केवल और रत्न बच गए हैं। अपनी प्राप्तियों का आनंद लें और सपने में लीन हों।',
  gameDescribe307:
    'त्रिपल 7, सबसे जादुई संख्या। क्लासिक 1x3 वन-आर्म बैंडिट: आसानी से शर्त लगाएं और विशाल संपत्ति जीतें। आखों खोलकर 100 गुना बदलें अपना पुरस्कार। अपनी भाग्यशालीता का परीक्षण करें और त्रिपल 7 की अल्ट्रा-आशीर्वाद ले जाएं।',
  gameDescribe308:
    'क्या आपको भाग्यशाली महसूस हो रहा है? अपनी पसंदीदा संख्या चुनें और स्टार्ट दबाएं। हाथ में जीत के लिए इसे घुमाएं। एक सरल और यादृच्छिक डिजिट की कंबिनेशन के साथ धन कमाएं। कौन कहता है कि आप एक क्लासिक वन-आर्म बैंडिट के साथ एक बड़े पुरस्कार नहीं जीत सकते हैं?',
  gameDescribe309:
    'सभी प्रकार के स्लॉट मशीनों में, बफलो एक सच्ची किंवदंती है। यह लास वेगास में हमेशा सबसे लोकप्रिय रहा है। अब बफलो GFG को ताकतवर बनाने के लिए तैयार है, जो आपको इसकी उबाल के साथ एक महान धन की यात्रा पर ले जाएगा।',
  gameDescribe310:
    'क्या आप मृत्यु की किताब में छिपी रहस्यों को खोलने के लिए तैयार हैं? 3 मृत्यु की किताबों के साथ इजिप्ट की गुप्त खजाने के द्वार खोलें। 200 मुफ्त गेम तक जीतें। अनंत धन के स्वामी बनने का सपना सच हो सकता है।',
  gameDescribe311:
    'यहाँ संपदा की प्रतिष्ठित धरती है। सभी को उच्च गति की अवसर मिलता है। अपने हाथों से दरिद्रता से संपदा की ओर जाने का मौका प्राप्त करें। लड़ो! लड़कर सच्ची महिमा और धन के लिए खून, पसीना और आंसू के साथ।',
  gameDescribe313:
    'पवित्र और श्रेष्ठ शुभ जानवर - लॉंगलॉंगलॉंग। जीएफजी में सबसे विश्रामयुक्त 1X3 स्लॉट मशीन फिर से प्रकट हो रही है, 100 गुना पुरस्कार जीतने का मौका है! अब नगण्य धन का अद्यतन करें, और जो व्यक्ति आप देखते हैं, वह बड़ी संपत्ति प्राप्त करेगा!',
  gameDescribe320:
    'शुभ्र, ह्रदय, हीरा और पत्ते द्वारा अभूषित एक मनमोहक दृश्य। ऐस, राजा, रानी और जोकर द्वारा बुना गया एक रोमांचक दुनिया में स्थिति। 52 कार्डों से मिलकर बनी इस काल्पनिक दुनिया में, जिसके पास परम ट्रंप कार्ड होता है, वह समृद्धि के गोपनीय को धारण करता है।',
  gameDescribe323:
    'रहस्यमय इस धरती में, अमरत्व का रहस्य और असंख्य धन आपका इंतजार कर रहे हैं। नवीनतम लॉन्च किए गए Pokey को खेलें और स्वर्गीय धरती में अपने आमंत्रण की पुष्टि करें। तीन सोने के गंगूबा के साथ अपार धन प्राप्त करें।',
  gameDescribe324:
    'क्या आप याद करते हैं, हम छोटे होने पर अपने गुल्दस्ते में संग्रहीत वे प्यारे सिक्के? क्लासिक GFG 1X3 स्लॉट आपके बचपन के सपने को साकार करेगा। अपनी जीत को अधिकतम 888 गुना तक बढ़ते देखें।',
  gameName320: 'अल्टीमेट ट्रंप कार्ड',
  qw: 'आधिकारिक प्रमाणन',
  qw_des:
    'GFG दुनिया का शीर्ष गेम सप्लायर है। हमारे उत्साह और नवाचार, विविध शैलियों, हिलने वाले ध्वनि प्रभाव, आकर्षक प्रदर्शन और निष्पक्ष गेम तंत्र को हमारे गेम में पूर्णतः एकीकृत करके, जीवन में खुशी को एकीकृत करते हैं और ग्राहकों को सर्वश्रेष्ठ मनोरंजन अनुभव प्रदान करते हैं।',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'प्रचार वीडियो',
  fishname: 'धमाकेदार मछली पकड़ने का खेल',
  fishname2: `स्वागत है धमाकेदार मछली पकड़ने के खेल की समुद्र की दुनिया में!<br/>
  यहाँ, मजा और चुनौती हर कहीं हैं!<br/>
  हमारे साथ इस रोमांचक मछली पकड़ने की यात्रा पर चलें!`,
  languageChange: 'ภาษา'
}
