import Vue from 'vue'
import VueRouter from 'vue-router'
// import global_ from '@/components/tool/Global.js'


Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    // {
    //   path: '/hometest',
    //   name: 'hometest',
    //   component: () => import('@/views/index'),
    //   meta: {
    //     type: 'mobile'
    //   }
    // },
    {
      path: '/home',
      name: 'Home',
      component: () => import('@/views/mobile/index'),
      meta: {
        type: 'mobile'
      }
    },
    {
      path: '/allgames',
      name: 'allgames',
      component: () => import('@/views/allgames'),
      meta: {
        type: 'mobile'
      }
    },
    {
      path: '/details',
      name: 'Details',
      component: () => import('@/views/details'),
      meta: {
        type: 'mobile'
      }
    },
    {
      path: '/event',
      name: 'Event',
      component: () => import('@/views/event'),
      meta: {
        type: 'mobile'
      }
    },
    // pc
    // {
    //   path: '/pc/home2',
    //   name: 'PcHome',
    //   component: () => import('@/views/pc/index'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    // {
    //   path: '/pc/allgames',
    //   name: 'pcallgames',
    //   component: () => import('@/views/pc/pcallgames'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    // {
    //   path: '/pc/details',
    //   name: 'PcDetails',
    //   component: () => import('@/views/pc/details'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    // {
    //   path: '/pc/event',
    //   name: 'PcEvent',
    //   component: () => import('@/views/pc/event'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    // -----------------------pc端路由------------------------------
    {
      path: '/pc/home',
      name: 'PcHome',
      component: () => import('@/views/pc/home'),
      meta: {
        type: 'pc'
      }
    },
    {
      path: '/pc/gameDisplay',
      name: 'PcGameDisplay',
      component: () => import('@/views/pc/gameDisplay'),
      meta: {
        type: 'pc'
      }
    },
    {
      path: '/pc/gameDisplayDetails',
      name: 'PcDetails',
      component: () => import('@/views/pc/gameDisplayDetails'),
      meta: {
        type: 'pc',
        ppath: '/pc/gameDisplay'
      }
    },
    // {
    //   path: '/pc/aboutUs',
    //   name: 'PcAboutUs',
    //   component: () => import('@/views/pc/aboutUs'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    // {
    //   path: '/pc/partners',
    //   name: 'PcPartners',
    //   component: () => import('@/views/pc/partners'),
    //   meta: {
    //     type: 'pc'
    //   }
    // },
    {
      path: '/pc/contactUs',
      name: 'PcContactUs',
      component: () => import('@/views/pc/contactUs'),
      meta: {
        type: 'pc'
      }
    },
    {
      path: '/pc/partners',
      name: 'PcContactUs',
      component: () => import('@/views/pc/partners'),
      meta: {
        type: 'pc'
      }
    }
  ],
  
})

const isMobile = () => /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent);

router.beforeEach((to, from, next) => {
  if (!isMobile() && to.path === '/event') {
    next({ path: '/pc/event'})
  } else {
    //当移动端试图访问pc端页面时
    if (isMobile() && to.meta.type != 'mobile') {
      next({ path: '/home'})
    }
    // 当pc端页面试图访问移动端页面时
    if (!isMobile() && to.meta.type !== 'pc') {
      next({ path: '/pc/home'})
    }
  }

  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
