export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'Últimos jogos',
  games_BoxLast: 'Roma ',
  newGames: 'Juegos populares',
  games: 'Pantalla de juego',
  games_more: 'Aprende más',
  games_newdes:
    'Diversos juegos, temas diversos y múltiples jugabilidades. Nos esforzamos por proporcionar a nuestros clientes la mejor experiencia de juego.',
  games_des:
    'Múltiples modalidades de juego, gráficos deslumbrantes, tramas con una destreza artesanal única;Cada momento es una nueva exploración y una sorpresa, ¡experimenta una vivencia de juego nunca antes vista!',
  games_newspan: 'Todos los juegos',
  aboutUstext1: 'Sobre nosotros',
  aboutUstext2:
    'A GFG é a principal fornecedora de jogos do mundo. A paixão é o que reúne especialistas de todo o mundo para formar uma equipe colaborativa. A responsabilidade é o motivo pelo qual damos a maior prioridade ao jogo justo em nosso desenvolvimento de jogos. Possibilidades infinitas é o que criou o GFG Game World - um mundo de pura imaginação.',
  aboutUstext3:
    'A GFG é a principal fornecedora de jogos do mundo. A paixão é o que reúne especialistas de todo o mundo para formar uma equipe colaborativa. A responsabilidade é o motivo pelo qual damos a maior prioridade ao jogo justo em nosso desenvolvimento de jogos. Possibilidades infinitas é o que criou o GFG Game World - um mundo de pura imaginação.',
    mainData_h2: '1',
  mainData_p: 'API única',
  mainData_span:
    'Solo necesita una API para integrar perfectamente juegos emocionantes como ajedrez, tragamonedas, pesca, etc.',
  mainCenter_h2: '5',
  mainCenter_p: 'Compatible con cinco dispositivos',
  mainCenter_span: 'El juego admite IOS / Android / Windows / MacOS / HTML5.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 idiomas admitidos',
  mainLanguage_span:
    'Estamos comprometidos a permitir que los jugadores usen sus idiomas nativos para mejorar la comprensión del contenido, sumergirse en los juegos y disfrutar de una fascinante experiencia de juego.',
  mainSafety_h2: '24',
  mainSafety_p: 'Servicio en línea las 24 horas',
  mainSafety_span:
    'No solo ofrecemos juegos, sino que también tenemos un equipo de atención al cliente las 24 horas, brindándole el servicio más extremo.',
  about: 'Socios',
  about_des:
    'El mejor socio de Asia en los negocios, con presencia en el este de Asia, el sudeste de Asia, China, Japón, Corea del Sur, spainishnam, Tailandia, Indonesia y Malasia.',
  vision: 'Contáctenos',
  vision_des:
    'Operadores, agentes y proveedores de red. Si está interesado en nuestros productos, no dude en contactarnos. Nos pondremos en contacto con usted lo antes posible.',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'COPYRIGHT © BUENA SUERTE GAMING TODOS LOS DERECHOS RESERVADOS.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'Últimas noticias',
  nav_All: 'Todo',
  nav_Latest: 'Últimos juegos',
  nav_Preview: 'Eventos',
  nav_Maintain: 'Mantenimiento',
  hot_BoxFirst: '',
  hot_BoxLast: 'Roma',
  //游戏大全
  games_h1: 'Juegos',
  navAll: 'Todo',
  navFish: 'Pesca',
  navChess: 'Juegos de mesa',
  navSlot: 'Ranuras',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'Roma',
  // 游戏内页
  inner1: 'Página de inicio',
  inner2: 'Introducción al juego',
  inner3: 'Especificaciones del juego',
  inner4: 'Nombre del juego',
  inner5: 'Tipo de juego',
  inner6: 'Volatilidad del juego',
  inner7: 'Moderado',
  inner8: 'RTP',
  inner9: 'Multiplicador máximo',
  inner10: 'Tamaño completo',
  inner11: 'Descarga inicial',
  inner12: 'Compatibilidad',
  inner13: 'Idiomas compatibles',
  //游戏名称
  gameName5: 'Rojo o Negro',
  gameName9: 'Bacará',
  gameName10: 'Toro-Toro',
  gameName11: 'Juego del Tigre Dragón',
  gameName12: 'Flor de oro',
  gameName14: 'Banquero de Este Bar',
  gameName18: 'Veintiuna',
  gameName22: 'Banquero Pai Gow',
  gameName25: 'Casino Toro-Toro',
  gameName27: 'Comodín de Toro-Toro',
  gameName29: 'Bacará sin comisiones',
  gameName32: 'Súper Toro-Toro',
  gameName113: 'Banquero Toro-Toro',
  gameName115: 'Banquero Toro-Toro de Tres cartas arriba',
  gameName117: 'Banquero Tres Barajas',
  gameName126: 'Banquero Toro-Toro de Cuatro cartas arriba',
  gameName201: 'Pez Sapo Dorado',
  gameName202: 'Dios Pez de la Riqueza',
  gameName203: 'Dragón',
  gameName204: 'Historia de la sirena',
  gameName205: 'Tiro de la Copa del Mundo',
  gameName301: 'Pixiu de la suerte',
  gameName302: 'Buena fortuna',
  gameName303: 'Misión de Navidad',
  gameName304: 'Wang Lai',
  gameName305: 'Victoria de Mahjong',
  gameName306: 'Piedra preciosa',
  gameName307: '777',
  gameName308: 'Comodín Triple',
  gameName309: 'Búfalo',
  gameName310: 'Libro de la Muerte',
  gameName311: 'Roma',
  gameName313: 'LongLongLong',
  gameName323: 'Tesoro del País de las Maravillas',
  gameName324: 'Fortuna del Cerdito',
  gameDescribe5:
    'Dos de las tres cartas de cada lado (Rojo o Negro) se revelarán al comienzo de cada juego. ¡Sigue tu intuición y astucia para elegir un lado ganador!',
  gameDescribe9:
    'Uno de los juegos de casino más populares en todo el mundo. Con varias posibles combinaciones de cartas, ¿qué estrategia emplearías? ¿Será un juego de azar, o una elección intuitiva?',
  gameDescribe10:
    '¿Cansado de jugar Banker Bull-Bull y quieres agregar un poco de emoción? Prueba este juego donde un grupo de jugadores se reúnen alrededor de una mesa para hacer apuestas. ¡Haz tus predicciones de victoria apostando en uno o varios lados!',
  gameDescribe11:
    'Similar al Baccarat sin comisión. Apuesta al dragón, apuesta al tigre. El ganador se lo lleva todo, con el banquero mirando desde la pared.',
  gameDescribe12:
    'Frente a tus oponentes con el misterio de sus manos no reveladas, tienes una decisión que tomar siguiendo su apuesta. ¿Llamar o no llamar? Cada ronda pone a prueba tu tenacidad, ingenio y sabiduría al máximo.',
  gameDescribe14:
    'Un popular juego de cartas entre la gente común que se originó en las provincias costeras de la región del Delta del Río Yangtsé en China. Una vez que se ha decidido el banquero con el volteo de una carta, comienza una batalla de estrategia y suerte.',
  gameDescribe18:
    'Un juego de cartas con un número limitado de combinaciones posibles pone a prueba tu memoria, habilidades analíticas y computacionales al calcular las probabilidades. ¡Haz lo mejor que puedas y deja el resto al destino!',
  gameDescribe22:
    'Juega el juego de dominós favorito de Wei Xiaobao con el giro adicional de tener la oportunidad de ser el banquero. ¡Sumérgete en una experiencia completamente nueva con multiplicadores más altos y rondas extra!',
  gameDescribe25:
    'Selecciona un único monto multiplicador al inicio de cada juego y prepárate para un enfrentamiento. El ganador se lo lleva todo, y el perdedor solo paga al ganador. Esta es tu oportunidad de apostar poco y ganar mucho.',
  gameDescribe27:
    'Los comodines se incluyen en este clásico juego de cartas del banquero para agregar un elemento de carta comodín. Pueden ser designados por su titular como cualquier carta que elijan, añadiendo así un elemento adicional de sorpresa a las posibles combinaciones de cartas.',
  gameDescribe29:
    'Baccarat sin comisiones no cobra comisiones por las victorias de la mano del banquero. ¡Siente la emoción de recibir pagos equitativos con Baccarat sin comisiones!',
  gameDescribe32:
    '¿Cansado de jugar a Banker Bull-Bull y quieres darle más emoción? Prueba este juego en el que una multitud de jugadores se reúnen alrededor de una mesa para hacer apuestas. ¡Haz tus predicciones de victoria apostando por uno o varios lados!',
  gameDescribe113:
    'Un juego clásico de Banker Bull-Bull. ¿Aprovecha la oportunidad de ser el banquero y barrer la baraja?',
  gameDescribe115:
    'Una variante nueva popular del clásico bien conocido Banker Bull-Bull. Tres de cinco cartas se revelarán al comienzo de cada mano. Teniendo toda esta información a tu alcance, ¿cómo decidirás qué hacer a continuación?',
  gameDescribe117:
    'Una variante más sencilla de los juegos de cartas de banca que es fácil de aprender. ¡Ven y prueba tu fortuna en este juego de suerte!',
  gameDescribe126:
    'Una nueva variante popular del clásico bien conocido "Banker Bull-Bull". Se revelarán cuatro de las cinco cartas al comienzo de cada mano. Con toda esta información a tu alcance, ¿cómo decidirás qué hacer a continuación?',
  gameDescribe201:
    'Hubo una vez un Sapo Dorado milenario que escupía dinero, y se rumorea que está por regresar a la tierra de los vivos. ¿Estás listo para saquear todos los tesoros del mar?',
  gameDescribe202:
    'El Dios de la Riqueza ha llegado, trayendo riquezas y tesoros. Se lanzan misiles perforadores imparables, las municiones de la ametralladora se acumulan rápidamente hasta llegar a la munición total de 999. ¡Ven y demuestra tu destreza!',
  gameDescribe203:
    'La leyenda sostiene que una vez que recojas todas las siete bolas de dragón, puedes invocar al Emperador Dragón y hacer un deseo. El Emperador Dragón ha sido avistado en los mares profundos, y con él la promesa de abundancia y buena suerte.',
  gameDescribe204:
    'Las melodías etéreas de las canciones de las sirenas flotan sobre el mar a medianoche, y la promesa de un increíble tesoro está en el fondo del mar... ¡Zarpemos, valientes aventureros! ¡Vamos a explorar la Leyenda de la Sirena!',
  gameDescribe205:
    'Una batalla entre leyendas. Los mejores tiradores asedian y capturan la fortaleza del enemigo. ¿Quién logrará abrirse paso y dominar la Copa del Mundo? ¡Experimenta la locura del fútbol con el World Cup Shooting de GFG!',
  gameDescribe301:
    '¡No solo atrae riqueza, sino que también otorga buena suerte! ¡Ganas giros extra cuando dos carretes coinciden en línea, lo que mejora enormemente tu tasa de victoria! ¡Haz girar los carretes de la fortuna y observa cómo tus ganancias se multiplican hasta un máximo de 888 veces!',
  gameDescribe302:
    '¡La tragaperras de Año Nuevo Chino de GFG! La rueda de la buena fortuna gira. ¡Multiplica tus ganancias con comodines especiales hasta un máximo de 3000X! ¡Escucha el llamado del gong de la fortuna! ¡Deseamos a todos un próspero año de éxito!',
  gameDescribe303:
    'Clinkety-clink, ¡escucha el tintineo de las monedas cayendo! ¡GFG te invita a celebrar esta temporada festiva con la Misión de Navidad! ¡Recoge 3 Trineos de la Fortuna y gana al instante el pago base más juego gratis! ¡Gana hasta 200 giros gratis!',
  gameDescribe304:
    'GFG - ¡Wang Lai te traerá suerte y riqueza! ¡Multiplica tus ganancias con comodines en Wang Lai! ¡Recoge faroles de la fortuna y gana al instante un mínimo de 8 tiradas gratis. ¡Gana hasta 99 juegos gratis consecutivos adicionales!',
  gameDescribe305:
    '¡Mahjong Win, una esencia de la cultura nacional, llega a GFG! 3 símbolos de victoria en autodibujo activan 12 tiradas gratis. ¡Gana hasta 10 veces el bono con eliminaciones continuas! ¡Ven y prueba tu suerte para convertirte en el próximo Rey del Mahjong!',
  gameDescribe306:
    'GFG pasa todas las noches contigo. Recoge tres meteoros para desencadenar una lluvia de meteoros. Con el mundo aislado del ruido de AKQJ, solo quedan más gemas. Disfruta de tus logros e inmérgerte en un sueño.',
  gameDescribe307:
    'Triple 7, el número más mágico. Clásica máquina tragaperras de una sola palanca 1x3: apuesta con facilidad y gana una inmensa fortuna. Multiplica tu recompensa por 100 veces. Prueba tu suerte y llévate a casa las bendiciones ultra emocionantes del triple 7.',
  gameDescribe308:
    '¿Te sientes afortunado? Elige tu número favorito y presiona iniciar. Deja que gire para la victoria al alcance de la mano. Gana una fortuna con una combinación simple y aleatoria de dígitos. ¿Quién dice que no se puede ganar un gran premio con una clásica tragaperras de una sola palanca?',
  gameDescribe309:
    'Entre todas las variedades de máquinas tragaperras, Buffalo es una verdadera leyenda. Ha sido consistentemente la más popular en Las Vegas. Ahora Buffalo está a punto de dominar GFG, llevándote en un viaje para hacer una gran fortuna con su efervescencia.',
  gameDescribe310:
    '¿Estás listo para desvelar los secretos en el Libro de los Muertos? Abre la puerta al tesoro secreto en Egipto con 3 Libros de los Muertos. Gana hasta 200 juegos gratis. El sueño de poseer riquezas eternas puede hacerse realidad.',
  gameDescribe311:
    'Esta es la tierra prometida de la abundancia. Todos tienen la oportunidad de ir a toda velocidad. Gana la oportunidad de pasar de la miseria a la riqueza con tus propias manos. ¡Lucha! Lucha por la gloria y las fortunas absolutas con sangre, sudor y lágrimas.',
  gameDescribe313:
    'La bestia sagrada y noble auspiciosa - Longlonglong. La máquina tragaperras clásica 1X3 vuelve a aparecer en GFG, ¡con la oportunidad de ganar un premio 100 veces mayor! ¡Obtén la suerte de la bendición del dragón ahora, y todos los que veas obtendrán una enorme riqueza!',
  gameDescribe320:
    'Adornado por Espadas, Corazones, Diamantes y Tréboles. Un paisaje impresionante entrelazado por Ases, Reyes, Reinas y Jokers. En este mundo de fantasía compuesto por 52 cartas, quien tiene la carta triunfo definitiva posee el secreto de las riquezas abundantes.',
  gameDescribe323:
    'En esta tierra de misterio, el secreto de la inmortalidad y riquezas incontables te esperan. Ven y juega al Pokey, recién lanzado por GFG, para validar tu invitación a la tierra celestial. Gana tus fortunas infinitas con tres gongs dorados.',
  gameDescribe324:
    '¿Recuerdas esas monedas queridas en nuestras alcancías cuando éramos jóvenes? La clásica tragaperras GFG 1X3 hará realidad tus sueños de la infancia. Ven y mira cómo tus ganancias se multiplican hasta un máximo de 888 veces.',
  gameName320: 'Súper As',
  qw: 'CERTIFICACIÓN OFICIAL',
  qw_des:
    'GFG es el principal proveedor de juegos del mundo. Al verter nuestro entusiasmo e innovación, estilos diversificados, efectos de sonido conmovedores, actuaciones atractivas y mecanismo de juego justo, se integra perfectamente en nuestro juego. Integre la alegría en la vida y brinde a los clientes la mejor experiencia de entretenimiento.',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'Video promocional',
  fishname: 'Juego de Pesca de Moda',
  fishname2: `¡Bienvenido al mundo submarino del juego de pesca de moda!<br/>
  ¡Aquí, la diversión y los desafíos están en todas partes!<br/>
  ¡Únete a nosotros en este emocionante viaje de pesca!`,
  languageChange: 'Idioma'
}
