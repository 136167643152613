export default {
  difference: 'Difference',
  makes: 'Makes the Difference',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst: 'เกมล่าสุด',
  games_BoxLast: 'รอมา ',
  newGames: 'เกมยอดนิยม',
  games: 'การแสดงเกม',
  games_more: 'เรียนรู้เพิ่มเติม',
  games_newdes:
    'เกมต่างๆ ธีมต่างๆ และเกมที่มีการเล่นหลากหลาย เราพยายามที่จะนำประสบการณ์เกมที่ยอดเยี่ยมที่สุดให้กับลูกค้าของเรา',
  games_des:
    'विविध खेलने की शैलियाँ, आकर्षक ग्राफ़िक्स, अनूठी और कुशलता से बनी कथाएँ;प्रति क्षण एक नई खोज और आश्चर्य से भरपूर है, एक ऐसा गेमिंग अनुभव महसूस करें जिसे आपने पहले कभी नहीं देखा हो!',
  games_newspan: 'ทุกเกม',
  aboutUstext1: 'เกี่ยวกับเรา',
  aboutUstext2:
    'GFG เป็นผู้ให้บริการเกมชั้นนำของโลก ความหลงใหลเป็นสิ่งที่รวมผู้เชี่ยวชาญจากทั่วโลกมาสร้างทีมงานที่ร่วมมือกัน ความรับผิดชอบเป็นเหตุผลที่เราให้ความสำคัญสูงสุดในการพัฒนาเกมที่เป็นเล่นเท่านั้น ความเป็นไปได้ที่ไม่มีที่สิ้นสุดเป็นสิ่งที่สร้างสรรค์โลกเกม GFG - โลกที่เต็มไปด้วยจินตนาการบริสุทธิ์',
  aboutUstext3:
    'GFG เป็นผู้ให้บริการเกมชั้นนำของโลก ความหลงใหลเป็นสิ่งที่รวมผู้เชี่ยวชาญจากทั่วโลกมาสร้างทีมงานที่ร่วมมือกัน ความรับผิดชอบเป็นเหตุผลที่เราให้ความสำคัญสูงสุดในการพัฒนาเกมที่เป็นเล่นเท่านั้น ความเป็นไปได้ที่ไม่มีที่สิ้นสุดเป็นสิ่งที่สร้างสรรค์โลกเกม GFG - โลกที่เต็มไปด้วยจินตนาการบริสุทธิ์',
    mainData_h2: '1',
  mainData_p: 'API เดียว',
  mainData_span:
    'พอร์ทที่ดีที่สุดสำหรับเกมส์ที่น่าสนใจเช่น ไพ่ป๊อกเด้ง เกมส์สล็อต และการตกปลา สามารถทำได้ด้วยอันเดียว API',
  mainCenter_h2: '5',
  mainCenter_p: 'สามารถใช้ร่วมกับห้าอุปกรณ์',
  mainCenter_span: 'เกมนี้รองรับ IOS / Android / Windows / MacOS / HTML5.',
  mainLanguage_h2: '10',
  mainLanguage_p: '10 ภาษาที่รองรับ',
  mainLanguage_span:
    'เรามุ่งมั่นที่จะอนุญาตให้ผู้เล่นใช้ภาษาท้องถิ่นของพวกเขาเพื่อให้เข้าใจเนื้อหามากขึ้น   ลุยเกมและสนุกกับประสบการณ์การเล่นที่น่าทึ่ง',
  mainSafety_h2: '24',
  mainSafety_p: 'บริการออนไลน์ 24 ชั่วโมง',
  mainSafety_span:
    'เราไม่ใช่แค่มีเกมส์เท่านั้น แต่ยังมีทีมบริการลูกค้ารอบนาที ให้บริการที่สุดท้ายกับคุณ',
  about: 'พันธมิตร',
  about_des:
    'พาร์ทเนอร์ที่ดีที่สุดในธุรกิจในเอเชีย มีการเข้าถึงในภูมิภาคออสเตรเลีย เอเชียตะวันออกเฉียงใต้ จีน ญี่ปุ่น เกาหลีใต้ เวียดนาม ประเทศไทย อินโดนีเซีย และมาเลเซีย',
  vision: 'ติดต่อเรา',
  vision_des:
    'ผู้ประกอบการ ตัวแทน และผู้ให้บริการเครือข่าย หากท่านสนใจผลิตภัณฑ์ของเรา กรุณาติดต่อเรา เราจะติดต่อกลับท่านโดยเร็วที่สุด',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'ลิขสิทธิ์ © ดีเฟอร์จูน เกมมิ่ง สิทธิ์ทั้งหมดถูกจำกัดไว้',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1: 'ข่าวล่าสุด',
  nav_All: 'ทั้งหมด',
  nav_Latest: 'เกมล่าสุด',
  nav_Preview: 'กิจกรรม',
  nav_Maintain: 'การบำรุงรักษา',
  hot_BoxFirst: '',
  hot_BoxLast: 'รอมา',
  //游戏大全
  games_h1: 'เกม',
  navAll: 'ทั้งหมด',
  navFish: 'การตกปลา',
  navChess: 'เกมบอร์ด',
  navSlot: 'ช่อง',
  allgames_BoxFirst: '',
  allgames_BoxLast: 'รอมา',
  // 游戏内页
  inner1: 'หน้าแรก',
  inner2: 'การแนะนำเกม',
  inner3: 'ข้อมูลเกม',
  inner4: 'ชื่อเกม',
  inner5: 'ประเภทเกม',
  inner6: 'ความผันผวนของเกม',
  inner7: 'ปานกลาง',
  inner8: 'โปรโตคอลส่งสด',
  inner9: 'ตัวคูณสูงสุด',
  inner10: 'ขนาดเต็ม',
  inner11: 'ดาวน์โหลดเริ่มต้น',
  inner12: 'ความเข้ากันได้',
  inner13: 'ภาษาที่รองรับ',
  // 游戏名称
  gameName5: 'สงครามแดงดำ',
  gameName9: 'บาคาร่า',
  gameName10: 'นิว นิว',
  gameName11: 'ศึกเสือมังกร',
  gameName12: 'ดอกไม้สีทอง',
  gameName14: 'ชิงเจ้า This Bar',
  gameName18: 'แบล็กแจ็ค',
  gameName22: 'ชิงเจ้า Pai Gow',
  gameName25: 'คาสิโน นิว นิว',
  gameName27: 'ไวล์ด นิว นิว',
  gameName29: 'บาคาร่า ไม่มีค่าคอม',
  gameName32: 'ซุปเปอร์ นิว นิว',
  gameName113: 'ชิงเจ้า นิว นิว',
  gameName115: 'ชิงเจ้า นิว นิว 3 ใบ',
  gameName117: 'ชิงเจ้า Three-Facecard',
  gameName126: 'ชิงเจ้า นิว นิว 4 ใบ',
  gameName201: 'ราชาคางคกทอง',
  gameName202: 'เทพไฉ่ชิงเอี๊ยะ',
  gameName203: 'มังกร',
  gameName204: 'ตำนานเมอร์เมด',
  gameName205: 'ยิงถ้วยบอลโลก',
  gameName301: 'ปี่เซียะนำโชค',
  gameName302: 'ดวงเฮงเล็งโชค',
  gameName303: 'คริสต์มาสมิชชั่น',
  gameName304: 'หวังไหล',
  gameName305: 'มาจองไร้เทียมทาน',
  gameName306: 'เจมสโตนส์',
  gameName307: '777',
  gameName308: 'โจ๊กเกอร์ 3 เท่า',
  gameName309: 'เจ้าทุย',
  gameName310: 'หนังสือแห่งความตาย',
  gameName311: 'โรม่าโคลอสเซียม',
  gameName313: 'ตรีพญามังกร',
  gameName323: 'นักล่าสมบัติอินวันเดอร์แลนด์',
  gameName324: 'หมูทองนำโชค',
  gameDescribe5:
    'สองใบจากสามใบจากทั้งสองฝั่ง (แดงหรือดำ) จะถูกเปิดเผยในตอนที่เริ่มเกมแต่ละรอบ ปฏิบัติตามสัญชาตญาณและสติเพื่อเลือกฝั่งที่จะชนะ!',
  gameDescribe9:
    'เป็นเกมคาสิโนที่ได้รับความนิยมมากที่สุดในทั่วโลก ด้วยการผสมผสานของการ์ดที่หลากหลาย คุณจะใช้กลยุทธ์อย่างไร? จะเป็นการพึ่งพาโชคชะตา, หรือเลือกตามสัญชาตญาณ?',
  gameDescribe10:
    'ท่านเบื่อไปแล้วใช่หรือไม่ จากการเล่น Banker Bull-Bull และต้องการเพิ่มความตื่นเต้น? ลองเล่นเกมนี้ที่มีผู้เล่นหลายคนรวมตัวกันที่โต๊ะเดียวกันเพื่อวางเดิมพัน ทำนายความชนะของคุณโดยการเดิมพันทางด้านเดียวหรือหลายด้าน!',
  gameDescribe11:
    'คล้ายกับบาคาร่าที่ไม่มีค่าคอมมิชชั่น พนันในมังกร, พนันในเสือ ผู้ชนะเอาทั้งหมด, ด้วยผู้เก็บเงินที่ดูจากบนกำแพง',
  gameDescribe12:
    'ในการเผชิญหน้ากับคู่แข่งขันที่มีความลึกลับของมือที่ไม่เปิดเผย, คุณต้องตัดสินใจตามการเดิมพันของพวกเขา ควรโทรหรือไม่ควรโทร? รอบแต่ละรอบจะทดสอบความทนทาน, ความฉลาด, และความรู้ของคุณอย่างสูงสุด',
  gameDescribe14:
    'เกมการ์ดที่นิยมในคนธรรมดาที่มาจากจังหวัดริมทะเลในภูมิภาคดลต้าแม่น้ำแยงซีในประเทศจีน หลังจากที่ได้ตัดสินผู้ธนาคารด้วยการพลิกการ์ด การต่อสู้ด้านกลยุทธ์และโชคลาภก็เริ่มขึ้น',
  gameDescribe18:
    'เกมการ์ดที่มีจำนวนการผสมผสานที่จำกัด จะทดสอบทักษะในการจดจำ, การวิเคราะห์ และการคำนวณของคุณเมื่อคำนวณความน่าจะเป็น ทำดีที่สุดแล้วปล่อยให้สิ่งที่เหลืออยู่กับโชคชะตา!',
  gameDescribe22:
    'เล่นเกมโดมิโนที่ Wei Xiaobao ชื่นชอบพร้อมทวิสต์การจับโอกาสเป็นธนาคาร เ immersed ตัวเองในประสบการณ์ใหม่ด้วยตัวคูณที่สูงขึ้นในรอบเพิ่มเติม!',
  gameDescribe25:
    'เลือกจำนวนคูณเดียวที่เริ่มต้นของแต่ละเกมและเตรียมตัวสำหรับการชิงชัย ผู้ชนะได้รับทั้งหมดและผู้แพ้จะจ่ายเงินให้ผู้ชนะเท่านั้น นี่เป็นโอกาสของคุณในการเดิมพันเล็ก ๆ และชนะใหญ่',
  gameDescribe27:
    'โจ๊กเกอร์ถูกนำมาใช้ในเกมการ์ดของผู้เล่นเพื่อเพิ่มองค์ประกอบของไพ่ตัวประกบ พวกเขาสามารถกำหนดโดยผู้ถือว่าเป็นไพ่ใดๆ ตามที่พวกเขาเลือก ทำให้เพิ่มความน่าตื่นเต้นเพิ่มเติมในการ์ดที่มีการผสมผสานที่เป็นไปได้',
  gameDescribe29:
    'บาคาร่าที่ไม่มีค่าคอมมิชชันไม่เรียกเก็บค่าคอมมิชชันเมื่อมือแบงค์ชนะ รู้สึกความตื่นเต้นของการรับการจ่ายเงินที่เท่ากันกับบาคาร่าที่ไม่มีค่าคอมมิชชัน!',
  gameDescribe32:
    'เบื่อกับการเล่นเกม Banker Bull-Bull และต้องการเพิ่มความท้าทาย? ลองเกมนี้ที่มีกลุ่มผู้เล่นหลายคนรวมตัวกันรอบโต๊ะเพื่อวางเดิมพัน ทำนายการชนะของคุณโดยการวางเดิมพันทางด้านหนึ่งหรือหลายด้าน!',
  gameDescribe113:
    'เกมคลาสสิคของ Banker Bull-Bull จับโอกาสเป็นแบงค์เกอร์และล้างสลับได้ไหม?',
  gameDescribe115:
    'รูปแบบใหม่ที่เป็นที่นิยมของเกมคลาสสิค Banker Bull-Bull ที่รู้จักกันดี สามใบจากห้าใบจะถูกเปิดเผยในตอนเริ่มของแต่ละมือ มีข้อมูลทั้งหมดอยู่ที่ปลายนิ้วของคุณ คุณจะตัดสินใจทำอะไรต่อไปอย่างไร?',
  gameDescribe117:
    'เป็นรูปแบบที่เรียบง่ายขึ้นของเกมการ์ดแบงค์เกอร์ที่ง่ายต่อการเรียนรู้ มาทดลองดวงชะตาของคุณในเกมนี้ที่พึ่งกับโชคชะตา!',
  gameDescribe126:
    'เป็นสายพันธุ์ใหม่ที่ได้รับความนิยมของเกมคลาสสิคที่เรียกว่า "Banker Bull-Bull" สี่ใบจากห้าใบของการ์ดจะถูกเปิดเผยในตอนแรกของแต่ละรอบ ด้วยข้อมูลทั้งหมดที่อยู่ในเท้ามือของคุณ คุณจะตัดสินใจทำอะไรต่อไปอย่างไร?',
  gameDescribe201:
    'เคยมีคาว่ามีคาว่ามีชาวธรรมชาติทองคำที่มีอายุถึงพันปีที่สะท้อนเงิน และมีข่าวลือว่าจะกลับมายังโลกแห่งชีวิต คุณพร้อมที่จะปล้นทรัพย์สมบัติทั้งหมดของทะเลหรือยัง?',
  gameDescribe202:
    'พระเจ้าแห่งความมั่งคั่งได้มาถึง นำความอุดมสมบูรณ์และสมบัติมาให้ เปิดโดยการยิงขีปนาวุธสว่านที่ไม่สามารถหยุดได้ การสะสมกระสุนปืนกลเร็วจนเต็ม 999 มาแสดงความสามารถของคุณ!',
  gameDescribe203:
    'ตามตำนานที่ระบุว่า หากคุณสะสมบอลมังกรทั้งหมดเจ็ดลูก คุณสามารถเรียกมังกรจักรพรรดิและอธิษฐาน มังกรจักรพรรดิได้ถูกพบอยู่ในทะเลลึก และพร้อมกับสัญญาว่าจะมีความอุดมสมบูรณ์และโชคดี',
  gameDescribe204:
    'เพลงเมอร์เมดที่ลึกลับ ดังขึ้นมาพร้อมกับท้องทะเลในเวลาเที่ยงคืน และมีคำสัญญาว่ามีสมบัติที่น่าทึ่งอยู่ที่สุดของทะเล... เรือที่ช้างรุ่งที่กล้าหาญทั้งหมด! มาสำรวจตำนานของเมอร์เมดกัน!',
  gameDescribe205:
    'การต่อสู้ของตำนาน นักยิงท็อปวางร้ายและยึดป้อมศัตรู ใครจะผ่านทางและครองเหรียญโลก? มาสัมผัสความเพลิดเพลินของฟุตบอลกับเกมส์โลกคัพชูติ้งของ GFG!',
  gameDescribe301:
    'ไม่เพียงแค่ดึงดูดความมั่งคั่ง มันยังให้โชคดี! เพิ่มการหมุนพิเศษด้วยลูกบนแนวเดียวที่ตรงกันเพิ่มอัตราการชนะของคุณอย่างมาก! หมุนลูกเสี่ยงที่ดีเพื่อดูว่ารางวัลของคุณจะเพิ่มขึ้นได้สูงสุดถึง 888 เท่า!',
  gameDescribe302:
    'สล็อตวันตรุษจีนของ GFG! ล้อเสี่ยงทายของโชคลาภหมุนต่อไป ทำให้รางวัลของคุณเพิ่มขึ้นกับไวลด์พิเศษได้สูงสุดถึง 3000 เท่า! ฟังเสียงกลองของโชคลาภ! ต้อนรับปีใหม่จีน ขอให้ทุกคนมีความเจริญรุ่งเรืองและประสบความสำเร็จในปีใหม่!',
  gameDescribe303:
    'ดังเสียงเงินสิงโตตกลงมา เคลิ้งเคลิ้ง! GFG เชิญชวนคุณร่วมสนุกในช่วงเทศกาลด้วย - Xmas Mission! สะสมเรือเท้าของโชคลาภ 3 คันและชนะโบนัสเริ่มต้นพร้อมเกมฟรีทันที! ชนะได้สูงสุดถึง 200 รอบฟรีสปิน!',
  gameDescribe304:
    'GFG - วังลายจะนำโชคลาภและความมั่งคั่งมาให้คุณ! เพิ่มรางวัลของคุณด้วยสัญลักษณ์ไวลด์บนวังลาย! สะสมหลอดโชคลาภและชนะเล่นฟรีอย่างน้อย 8 รอบทันที. ชนะได้สูงสุดถึง 99 รอบเกมฟรีต่อเนื่องเพิ่มเติม!',
  gameDescribe305:
    'มาจงวิน - ความเป็นประจำแห่งวัฒนธรรมชาติ - กำลังมาถึง GFG! สัญลักษณ์ชนะด้วยการจัดเอง 3 อันเป็นตัวกระตุ้นการหมุนฟรี 12 ครั้ง ชนะได้สูงสุด 10 เท่ากับโบนัสด้วยการกำจัดต่อเนื่อง! มาลองโชคของคุณและเป็นราชามาจงวินต่อไป!',
  gameDescribe306:
    'GFG ใช้เวลาทุกคืนกับคุณเสมอ สะสมดาวตกสามครั้งเพื่อนำเสนอฝนดาวตก โลกกำลังเว้นระยะห่างจากเสียงของ AKQJ เหลืออยู่เพียงเพชรมากขึ้นเท่านั้น สนุกกับความสำเร็จของคุณและตักเตือนตัวเองในความฝัน',
  gameDescribe307:
    'สามเจ็ดสามเจ็ดสามเจ็ด ตัวเลขที่มีเวทมนตร์ที่สุด สล็อตแบนดิต 1x3 คลาสสิก: เรามาเดิมพันอย่างสบายๆและชนะความมั่งคั่งที่มากมาย คูณรางวัลของคุณถึง 100 เท่า ลองโชคของคุณและนำความโชคดีสุดแห่งตัวเลขสามเจ็ดสามเจ็ดกลับบ้าน',
  gameDescribe308:
    'คุณรู้สึกโชคดีหรือเปล่า? เลือกเลขที่คุณชื่นชอบแล้วกดเริ่มต้น ให้มันหมุนเพื่อชัยชนะที่กำลังจะมาถึง เป็นที่รวยด้วยคอมโบชุดเลขที่เรียบง่ายและสุ่ม ใครว่าคุณจะไม่สามารถชนะรางวัลใหญ่ด้วยแบนดิตเครื่องเสือหน้าเดียว',
  gameDescribe309:
    'ในหมู่สล็อตแมชชีนทั้งหลาย บัฟฟาโล่เป็นตำนานที่แท้จริง มันเป็นที่นิยมมากที่สุดในลาสเวกัสเสมอ ตอนนี้บัฟฟาโล่กำลังจะควบคุม GFG และพาคุณไปในการเดินทางทำกำไรใหญ่ด้วยความฟุ้งเฟืองของมัน',
  gameDescribe310:
    'พร้อมที่จะเปิดเผยความลับในหนังสือมรณกรรมหรือยัง? เปิดประตูสู่สมบัติความลับในอียิปต์ด้วยหนังสือมรณกรรม 3 เล่ม ชนะสูงสุดถึง 200 เกมฟรี ความฝันของการเป็นเจ้าของทรัพย์สินที่อมตะอาจเป็นจริง',
  gameDescribe311:
    'นี่คือแดนแห่งความอุดมสมบูรณ์ ทุกคนมีโอกาสได้รับการเปิดเต็มที่ ได้รับโอกาสในการเปลี่ยนจากคนจนเป็นคนร่ำรวยด้วยมือของคุณเอง สู้! สู้เพื่อความรุ่งเรืองและความมั่งคั่งที่สมบูรณ์สมบัติด้วยเลือด น้ำเหลือง และน้ำตา',
  gameDescribe313:
    'สัตว์เปรียบเทียบที่สวยงามและเป็นสิ่งที่น่าประทับใจ - ล่องล่องล่อง. เครื่องสล็อต 1X3 ที่เป็นคลาสสิกที่สุดกำลังกลับมาใน GFG ซึ่งมีโอกาสได้รับรางวัล 100 เท่า! หาความโชคดีจากพระมหาเทพมังกรเดี๋ยวนี้และทุกคนที่คุณเห็นจะได้รับความมั่งคั่งอันใหญ่!',
  gameDescribe320:
    'ด้วยไพ่สเปด, ดอกหัวใจ, เพชร, และคลับเป็นตัวอย่าง ทัศนียภาพที่สวยงามที่แอส, กิง, ควีน, และโจ๊กเกอร์ผู้จัดการ ในโลกแห่งจินตนาการที่ประกอบด้วยไพ่ 52 ใบนี้ ผู้ที่มีไพ่ตัวแรกสุดจะถือเป็นความลับของความมั่งคั่งที่มั่นคง',
  gameDescribe323:
    'ในแดนของความลึกลับนี้ ความลมหายใจและความมั่งคั่งที่ไม่นับสิบของคุณรอคอยให้คุณมาเล่นเกม Pokey ที่เปิดตัวใหม่ของ GFG เพื่อยืนยันคำเชิญของคุณในดินแดนสวรรค์ สร้างทรัพย์สินที่ไม่มีที่สิ้นสุดของคุณด้วยก้องทองสามสาย',
  gameDescribe324:
    'คุณยังจำเหรียญที่เราเก็บในกระปุกเมื่อเรายังเป็นเด็กได้ไหม? สล็อตคลาสสิก GFG 1X3 จะทำให้ความฝันวัยเด็กของคุณเป็นจริง มาดูการชนะของคุณเพิ่มขึ้นได้สูงสุดถึง 888 เท่า',
  gameName320: 'ทรัมป์การ์ดสุดปัง',
  qw: 'การรับรองอย่างเป็นทางการ',
  qw_des:
    'GFG เป็นผู้จัดจำหน่ายเกมที่ดีที่สุดในโลก โดยการร pouring ความกระตือรือร้นและนวัตกรรมของเรา สไตล์ที่หลากหลาย เสียงผลกระทบที่น่าประทับใจ การแสดงที่น่าสนใจ และกลไกการเล่นที่เป็นธรรม เราได้นำมันมาผสมผสานให้เข้ากับเกมของเราอย่างสมบูรณ์แบบ เรานำความสุขเข้าไปในชีวิตและมอบประสบการณ์การสร้างความบันเทิงที่ดีที่สุดให้กับลูกค้า',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: 'วิวิดีโอการโฆษณา',
  fishname: 'ฮิตเกมตกปลา',
  fishname2: `ยินดีต้อนรับสู่โลกใต้ทะเลของเกมตกปลาที่ฮิต!<br/>
  ที่นี้มีความสนุกและความท้าทายทุกที่!<br/>
  เข้าร่วมกับเราในการเดินทางตกปลาที่ตื่นเต้นนี้กัน!`,
  languageChange: 'भाषा'
}
