export default {
  difference: '不凡',
  makes: '創造非凡',
  // the: 'THE',
  // difference2: 'DIFFERENCE',
  games_BoxFirst:'最新遊戲',
  games_BoxLast:'羅馬競技場 ',
  newGames:'熱門遊戲',
  games: '遊戲展示',
  games_more:'瞭解更多',
  games_newdes:'各式遊戲、多元主題、多樣玩法，我們致力於提供客戶最極致的遊戲體驗。',
  games_des: '多變玩法、絢麗畫面、獨具匠心的故事情節,<br/>每一刻都是全新探索與驚喜，感受前所未有的遊戲體驗！',
  games_newspan:'所有遊戲',
  aboutUstext1:'關於我們',
  aboutUstext2:'GFG大運電子是全球頂尖的遊戲供應商。熱情，讓我們把各地的專家組成合作無間的團隊。責任，讓我們將公平遊戲視為開發的最高準則。無限可能，讓我們創造出天馬行空的GFG遊戲世界。',
  aboutUstext3:'GFG大運電子是全球頂尖的遊戲供應商。<br/>熱情，讓我們把各地的專家組成合作無間的團隊。<br/>責任，讓我們將公平遊戲視為開發的最高準則。<br/>無限可能，讓我們創造出天馬行空的GFG遊戲世界。',
  mainData_h2:'1',
  mainData_p:'單一API',
  mainData_span:'只需一個API就可以完美對接棋牌、老虎機、捕魚等精彩遊戲。',
  mainCenter_h2:'5',
  mainCenter_p:'五大裝置兼容',
  mainCenter_span:'遊戲支援IOS/Android/Windows/MacOS/HTML5。',
  mainLanguage_h2:'10',
  mainLanguage_p:'支持十種語言',
  mainLanguage_span:'我們致力於讓玩家使用自己的母語，更好地瞭解遊戲內容、融入遊戲、享受更自在的遊戲體驗。',
  mainSafety_h2:'24',
  mainSafety_p:'二十四小時服務在線',
  mainSafety_span:'我們不只是提供遊戲，還有客服團隊全天候待命，為您提供最極致的服務。',
  about: '合作夥伴',
  about_des: '亞洲最佳合作夥伴，事業版圖擴及東亞、東南亞、中國、日本、韓國 、越南、泰國、印度尼西亞及馬來西亞等地區。',
  vision: '聯絡我們',
  vision_des: '運營商、代理商、包網提供商 若您有意對接我們的產品， 歡迎您與我們聯絡，將有專人為您提供服務。',
  year: 'Valletta Buildings, South Street, Valletta - VLT 1103 Malta',
  copyright: 'COPYRIGHT © GOOD FORTUNE GAMING ALL RIGHTS RESERVED.',
  end: 'GFG',
  home: 'HOME',
  stunning: 'STUNNING',
  design: 'DESIGN',
  spectacular: 'SPECTACULAR',
  team: 'TEAM',
  rigorous: 'RIGOROUS',
  code: 'CODE',
  elegant: 'ELEGANT',
  music: 'MUSIC',
  support: 'SUPPORT',
  broad: 'BROAD',
  market: 'MARKET',
  // 热门游戏
  hotgames_h1:'最新消息',
  nav_All:'全部消息',
  nav_Latest:'最新遊戲',
  nav_Preview:'精彩活動',
  nav_Maintain:'維護公告',
  hot_BoxFirst:'',
  hot_BoxLast:'羅馬競技場',
  //游戏大全
  games_h1:'遊戲',
  navAll:'全部遊戲',
  navFish:'魚機',
  navChess:'棋牌',
  navSlot:'虎機',
  allgames_BoxFirst:'',
  allgames_BoxLast:'羅馬競技場',
  // 游戏内页
  inner1:'首頁',
  inner2:'遊戲介紹',
  inner3:'遊戲規格',
  inner4:'遊戲名稱',
  inner5:'遊戲類型',
  inner6:'遊戲震幅',
  inner7:'中等的',
  inner8:'實時傳輸協議',
  inner9:'最大倍率',
  inner10:'全尺寸',
  inner11:'初始下載',
  inner12:'兼容',
  inner13:'支援語系',
  //游戏名称
  gameName5:'紅黑大戰',
  gameName9:'百家樂',
  gameName10:'百人牛牛',
  gameName11:'龍虎鬥',
  gameName12:'炸金花',
  gameName14:'搶莊二八槓',
  gameName18:'二十一點',
  gameName22:'搶莊牌九',
  gameName25:'通比牛牛',
  gameName27:'癩子牛牛',
  gameName29:'免佣百家樂',
  gameName32:'超級百人牛牛',
  gameName113:'新搶莊牛牛',
  gameName115:'新看三張搶莊牛牛',
  gameName117:'新搶莊三公',
  gameName126:'新看四張搶莊牛牛',
  gameName201:'金蟾捕魚',
  gameName202:'財神捕魚',
  gameName203:'神龍捕魚',
  gameName204:'人魚傳說',
  gameName205:'世足爭霸',
  gameName301:'貔貅開運',
  gameName302:'大運奪寶',
  gameName303:'聖誕任務',
  gameName304:'旺來',
  gameName305:'麻將無雙',
  gameName306:'寶石之星',
  gameName307:'777',
  gameName308:'三倍小丑',
  gameName309:'水牛',
  gameName310:'死亡之書',
  gameName311:'羅馬競技場',
  gameName313:'龍龍龍',
  gameName323:'仙境探寶',
  gameName324:'招財金豬',
  gameDescribe5:'開局便可知道紅黑兩陣營的兩張手牌，根據你的直覺與膽識，決定誰是勝利的一方。',
  gameDescribe9:'源自於義大利的經典牌桌遊戲百家樂，是世界各地賭場最受歡迎的遊戲之一。面對千變萬化的牌型組合，你決定使用甚麽策略?還是直接賭上運氣，靠直覺做出選擇?',
  gameDescribe10:'傳統的搶莊牛牛玩膩了嗎? 試試旁觀者外圍下注玩法，妳可以選擇一個甚至多個你喜歡的位置，預測他的勝利。',
  gameDescribe11:'如同免佣百家一般，押龍押虎，勝者可全拿，莊家作壁上觀。龍長吟，虎狂嘯，一張定生死。',
  gameDescribe12:'中國廣泛流傳，逢年過節會玩幾把的的一種民間多人紙牌遊戲，面對對手未知的手牌，這次的下注，跟還是不跟?比還是不比?每個回合都在考驗你的膽略與智慧來一把炸金花吧！',
  gameDescribe14:'源自於中國長江三角洲沿海省份的民間撲克遊戲，從第一步翻牌決定莊家，變開始了策略與運氣的對決。',
  gameDescribe18:'300多年前緣起於法國的撲克牌遊戲，有限的牌型數量，考驗你的記憶力、分析力、計算出可能的機率後，剩下的就交給老天吧!',
  gameDescribe22:'韋小寶最愛的骨牌遊戲結合撲克搶莊玩法，更高的倍數、更多的局數，享受不同的新體驗。',
  gameDescribe25:'只需要選擇一次倍數，就能一較高下，勝者通吃全殺，敗者只輸一人，以小搏大的好機會。',
  gameDescribe27:'在原本經典的搶莊撲克遊戲中，增加了大王小王的新元素，能夠取代任意牌型的強大功能，增加了手牌千變萬化的可能。',
  gameDescribe29:'遊戲規則直接捨棄了原本百家樂押莊贏錢收取佣金的機制，押莊贏了不用被抽佣金可以全拿回來的感覺，你一定要試一次。',
  gameDescribe32:'傳統的搶莊牛牛玩膩了嗎? 試試旁觀者外圍下注玩法，妳可以選擇一個甚至多個你喜歡的位置，預測他的勝利。',
  gameDescribe113:'經典的棋牌遊戲搶莊牛牛，搶莊準備一舉通吃?',
  gameDescribe115:'搶莊牛牛廣受歡迎的新穎玩法，在要你做任何決定之前，便大膽的提早開出五張中的三張手牌，得到這麽多資訊的你，該如何決定下一步呢?',
  gameDescribe117:'簡化版本的搶莊撲克遊戲，讓你更快了解遊戲，更純粹的比較運氣，快來試試吧。',
  gameDescribe126:'搶莊牛牛廣受歡迎的新穎玩法，在要你做任何決定之前，便大膽的提早開出五張中的四張手牌，得到這麽多資訊的你，該如何決定下一步呢?',
  gameDescribe201:'曾經有一隻會吐金錢的千年金蟾，如今即將再次重返人間，能一網打盡所有魚群的神奇漁網、散發神秘紫光的深水炸彈，你準備好一網打盡所有的寶藏了嗎?',
  gameDescribe202:'財神到、財神到、帶著滿滿的財寶，歡迎各路豪傑備妥武器來發財，鑽頭砲彈，前進突刺，誰也攔不住；機關子彈，瘋狂累積，湊滿999；等你大顯神威！',
  gameDescribe203:'傳說中只要你蒐集了七顆龍珠，便能向神龍許一個願望。龍神行大運，深海現蹤跡，一聲龍吟，直穿雲霄；定海神針，橫掃波滔。你，見到神龍了嗎?',
  gameDescribe204:'某片海域流傳著許多神秘傳說，霧起漂蕩的幽靈船；夜半飄渺的人魚歌；以及海洋最深處的巨額寶藏...啟程吧，勇敢的冒險者親自去探究人魚傳說！',
  gameDescribe205:'群雄爭勝，頂級射手為球隊攻城拔寨，誰能突圍成功，稱霸世界杯的舞台？GFG《世足爭霸》陪您一起身臨其境，感受世足狂熱。',
  gameDescribe301:'GFG最新招財獸－「貔貅開運」不只能招財，還能帶來好運氣！經典單線遊戲讓您好運連環！雙軸相同，額外翻盤，勝率大增！物件滿盤，招財轉輪，倍數翻倍，最高888倍！快來試試您的手氣，把貔貅好運帶回家！',
  gameDescribe302:'新春報喜，招財福運來報到！GFG同名迎春虎機－「大運奪寶」讓您行大運，奪巨寶！經典243路遊戲，輕鬆中獎！鴻運轉輪，百搭降臨，倍數倍增，最高3000倍！敲響賀歲銅鑼，幸運奪得8場以上免費遊戲獎賞，最高有機會可獲得99場！「大運奪寶」迎新年，春風得意一整年！',
  gameDescribe303:'叮叮噹，叮叮噹，滿屏金幣多響亮！大運電子歡樂鉅獻－聖誕任務，邀您一起歡慶佳節！歡喜財富翩然來到，聖誕老人親送五連線高賠率大獎！蒐集3個財富雪橇，立即奪得基本獎賞與免費遊戲獎賞！最高還可獲得200場！聖誕任務給您帶來幸福，將佳節大禮送到家！',
  gameDescribe304:'傳說有種神奇的果實，擁有它鴻運滾滾而來！大運電子開運老虎機－「旺來」為您添好運，招大財！經典243路遊戲，旺來百搭再翻倍，讓您中獎再加碼！蒐集福運天燈，立刻享有8場以上免費遊戲獎賞，最高可連續暢玩99場！立刻喜迎旺來，讓您福來運來旺旺來！',
  gameDescribe305:'千年國粹的精華-麻將無雙，即將在大運電子重現！這款經典消除類老虎機，中獎後獎圖自動轉成百搭符號，搭配1024 Ways讓你得分機率大暴增。3 個自摸出現，立即贏得 12 次免費遊戲，每個額外的自摸圖案另可觸發 2 次免費遊戲！高達10倍的連消加成，讓你連莊拉莊，槓上開花發更發想成為新一代麻將之王嗎？現在就來試試自己的手氣吧！',
  gameDescribe306:'滿天星斗落入凡塵，化作一顆顆耀眼的寶石，GFG老虎機，陪你一起蒐集、度過每一個夜晚。出現三顆流星，便會召喚流星雨，世界隔絕了AKQJ的喧鬧，只有寶石，更多的寶石!然後帶著滿滿的收穫，沉浸在璀璨的夢鄉。',
  gameDescribe307:'傳說7是最神奇的數字，給每個人帶來幸運加持！結合1X3的經典虎機，輕鬆押注，輕鬆中獎最高可獲得100倍大獎！現在就下場試試您的手氣，再把777的好運帶回家！',
  gameDescribe308:'感覺到炙熱的獲勝慾望了嗎？選個喜歡的數字、催動開關，為即將到手的勝利瘋狂旋轉簡單排列、任意組合都能帶來豐厚進帳誰說經典老虎機不能贏得超級大獎呢？',
  gameDescribe309:'如果老虎機世界中有真正的傳奇，那一定是Buffalo多年來穩坐拉斯維加斯最受歡迎的第一名現在Buffalo霸氣降臨GFG以它的兇猛衝勁，為您撞破財富閘門',
  gameDescribe310:'準備好揭開死亡之書的秘密了嗎？傳說中里面不但藏著永生的秘密，還有歷代法老財富的尋寶線索獲取3本死亡之書，即刻開啟埃及秘寶的大門！最高有機會免費加碼200場！讓您實現永生富裕的夢想！',
  gameDescribe311:'這是擁有豐盛饗宴的應許之地人人都有全力拼搏的機會用雙手撕扯出自己翻身的契機奮戰吧！用鮮血與生命換取絕對的光榮與財富',
  gameDescribe313:'神聖崇高的吉獸-龍龍龍，為每個人帶來千年的祥和與平安！最經典1X3的虎機，即將在GFG重現還有機會獲得百倍大獎！現在就領取神龍加持的幸運，見到的人都會獲得巨額財富吧！',
  gameDescribe323:'仙境之中，除了長生不老的秘密，就是享用不完的巨額財寶了GFG隆重推出仙境探寶老虎機，邀請您進入天宮祕境獲得3個金鑼，便能坐擁無盡財富現在就推開夢想的大門，體驗快活似神仙的感受',
  gameDescribe324:'還記得我們小時候把捨不得用的零花錢銅板一個個無比珍惜地放進心愛的小豬撲滿裡GFG招牌1X3老虎機，獎金最高888倍，實現您的童年夢想幫您輕鬆中大獎，讓錢變成您最喜歡的樣子',
  gameDescribe320:'黑桃、紅心、方塊跟梅花妝點這裡的每個角落Ace、King、Queen、Joker交織出華美風貌在這個52張紙牌組成的魔幻世界裡誰拿到超級王牌，誰就掌握真正的財富密碼',
  gameName320:'超級王牌',
  qw: '官方認證',
  qw_des:'GFG 是世界上顶尖的游戏供应商，我们提供多样化的风格、动听的音效、吸引人的表演和公平的游戏机制，完美融入我们的游戏中，将乐趣带入生活，为客户提供最佳的娱乐体验。',
  qw_1: 'An independent regulatory body responsible for the governance of all gaming activities in Malta.',
  qw_2: 'A private independent gaming testing laboratory and accredited with ISO 17020 Inspection Bodies.',
  qw_3: 'An independent committee to license commercial gaming in partnership with licensing authorities.',
  qw_4: 'An internationally recognised Accredited Testing Facility (ATF).',
  videgames: '動感震撼',
  fishname: '爆款捕魚',
  fishname2: `歡迎來到爆款捕魚的海底世界！<br/>
  在這裡，樂趣和挑戰無處不在！<br/>
  和我們一起開啓這段令人興奮的海底之旅吧！`,
  languageChange: '語言切換'
}